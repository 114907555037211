import axios from "axios";
import {
  FETCH_START,
  FETCH_FAILED,
  FETCH_QUIZ_LIST_SUCCEED,
  FETCH_QUIZ_SUCCEED,
  QUIZ_SET_STATE,
  QUIZ_FINISHED,
  QUIZ_NEXT_QUESTION,
  QUIZ_RETRY,
  GET_ERRORS,
} from "./types";
import setAuthToken from "../token/authtoken";

export function fetchQuizList() {
  return async (dispatch) => {
    dispatch(fetchStart());

    try {
      const response = await axios.get("quizList.json");
      const quizList = [];

      Object.keys(response.data).forEach((key, index) => {
        quizList.push({
          id: key,
          name: `Quiz #${index + 1}`,
        });
      });

      dispatch(fetchQuizListSucceed(quizList));
    } catch (error) {
      dispatch(fetchFailed(error));
    }
  };
}

export function sendTest(){
  return async (dispatch, getState) => {
    try {
      const state = getState().currentQuiz;
      const response = await axios.put(`/testQcm/finishTest`,state.quiz);
      // const quiz = response.data;
      // dispatch(fetchQuizSucceed(quiz));
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
      dispatch(fetchFailed(error));
    }
    
  };
}
export function fetchQuizById(token) {
  setAuthToken(token);
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await axios.get(`/testQcm/candidateQuestions`);
      const quiz = response.data;
      dispatch(fetchQuizSucceed(quiz));
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
      dispatch(fetchFailed(error));
    }
  };
}

export function fetchQuizAnswer(quiz,indx) {
  return {
    type: FETCH_QUIZ_SUCCEED,
    quiz: quiz,
    currentQuizQuestion: quiz[indx],
  };
}

export function quizFreeFieldAnswer(answer) {
  return (dispatch, getState) => {
    const state = getState().currentQuiz;
    // initialize variables
    let activeQuestionNumber = state.activeQuestionNumber;
    state.quiz[activeQuestionNumber].freeField = answer;

    dispatch(fetchQuizAnswer(state.quiz,activeQuestionNumber))
  };
}

export function quizAnswerClick(answer) {
  return (dispatch, getState) => {
    const state = getState().currentQuiz;
    // initialize variables
    let activeQuestionNumber = state.activeQuestionNumber;
    if(answer !== undefined){
      state.quiz[activeQuestionNumber].propositions[answer.index] = answer;
    }  

    dispatch(fetchQuizAnswer(state.quiz,activeQuestionNumber))
  };
}

export function onNextClick() {
  return (dispatch, getState) => {
    const state = getState().currentQuiz;
    let activeQuestionNumber = state.activeQuestionNumber;
    let isFinalQuestion = activeQuestionNumber + 1 === state.quiz.length;
    if (isFinalQuestion) dispatch(finishQuiz());
    else {
      let nextQuestionNumber = activeQuestionNumber + 1;
      dispatch(
        quizNextQuestion(nextQuestionNumber, state.quiz[nextQuestionNumber])
      );
    }
  };
}

export function fetchStart() {
  return {
    type: FETCH_START,
  };
}

export function fetchFailed(error) {
  return {
    type: FETCH_FAILED,
    error: error,
  };
}

export function fetchQuizListSucceed(quizList) {
  return {
    type: FETCH_QUIZ_LIST_SUCCEED,
    quizList: quizList,
  };
}

export function fetchQuizSucceed(quiz) {
  return {
    type: FETCH_QUIZ_SUCCEED,
    quiz: quiz,
    currentQuizQuestion: quiz[0],
  };
}

export function quizSetState(answerState, results) {
  return {
    type: QUIZ_SET_STATE,
    answerState: answerState,
    results: results,
  };
}

export function quizNextQuestion(nextQuestionNumber, nextQuizQuestion) {
  return {
    type: QUIZ_NEXT_QUESTION,
    activeQuestionNumber: nextQuestionNumber,
    currentQuizQuestion: nextQuizQuestion,
  };
}

export function finishQuiz() {
  return {
    type: QUIZ_FINISHED,
  };
}

export function retryQuiz() {
  return (dispatch, getState) => {
    const state = getState().currentQuiz;
    dispatch(resetQuizState(state.quiz[0]));
  };
}

export function resetQuizState(firstQuizQuestion) {
  return {
    type: QUIZ_RETRY,
    currentQuizQuestion: firstQuizQuestion,
  };
}
