import React, { useEffect, useState } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../style/candidature.css";
import { CANDIDATURE } from "../actions/types";
import { useDispatch, useSelector } from "react-redux";

function Candidature() {

  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.candidature &&
        dispatch({
          type: CANDIDATURE,
        });
    };
    !load.candidature && (img.src = "/sources/candid-img.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [condidatData, setCondidatData] = useState({
    nom: "",
    adresse: "",
    age: "",
    email: "",
    telephone: "",
    diplome: "",
    ecole: "",
    entrepriseActuelle: "",
    equipeActuelle: "",
    domaineExpertise: "",
    typeMission: "",
    anneeExperience: "",
    technologies: "",
    certifications: "",
    entrepriseInternationales: "",
    projetInternationales: "",
    situationFamiliale: "",
    disponibilite: "",
    typeContrat: "",
    salaire: "",
    pretentionSalaire: "",
    travailFrance: "",
    mobilite: "",
    contratFrance: "",
    visaDate: "",
    visaType: "",
    cv: "",
    lettreMotivation: "",
  });
  const [contactErreur, setContactErreur] = useState(true);
  const [btnForm, setBtnForm] = useState(false);
  const [formError, setFormError] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [nomValid, setNomValid] = useState(false);
  const [telValid, setTelValid] = useState(false);
  const [adresseValid, setAdresseValid] = useState(false);
  const [ageValid, setAgeValid] = useState(false);
  const [diplomeValid, setDiplomeValid] = useState(false);
  const [ecoleValid, setEcoleValid] = useState(false);
  const [situationValid, setSituationValid] = useState(false);
  const [travailValid, setTravailValid] = useState(false);
  const [mobiliteValid, setMobiliteValid] = useState(false);
  const [contratValid, setContratValid] = useState(false);
  const [cvValid, setCvValid] = useState(false);

  const onChange = (e) => {
    formError && setFormError(false);
    setCondidatData({ ...condidatData, [e.target.id]: e.target.value });
    if (e.target.name === "situationFamiliale")
      setCondidatData({ ...condidatData, [e.target.name]: e.target.id });
    if (e.target.name === "travailFrance")
      setCondidatData({ ...condidatData, [e.target.name]: e.target.id });
    if (e.target.name === "mobilite")
      setCondidatData({ ...condidatData, [e.target.name]: e.target.id });
    if (e.target.name === "contratFrance")
      setCondidatData({ ...condidatData, [e.target.name]: e.target.id });
    if (e.target.id === "cv" || e.target.id === "lettreMotivation")
      setCondidatData({ ...condidatData, [e.target.id]: e.target.files[0] });
    e.target.id === "ecole" && setEcoleValid(false);
    e.target.id === "email" && setEmailValid(false);
    e.target.id === "nom" && setNomValid(false);
    e.target.id === "adresse" && setAdresseValid(false);
    e.target.id === "age" && setAgeValid(false);
    e.target.id === "telephone" && setTelValid(false);
    e.target.id === "diplome" && setDiplomeValid(false);
    e.target.id === "ecole" && setEcoleValid(false);
    e.target.name === "situationFamiliale" && setSituationValid(false);
    e.target.name === "travailFrance" && setTravailValid(false);
    e.target.name === "mobilite" && setMobiliteValid(false);
    e.target.name === "contratFrance" && setContratValid(false);
    e.target.id === "cv" && setCvValid(false);
  };

  const input_fields = {
    tel: /\d{9,}$/,
    age: /^\d/,
    nom: /[a-zA-Z]/,
    //eslint-disable-next-line
    email: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!input_fields.email.test(condidatData.email)) setEmailValid(true);
    else setEmailValid(false);

    if (!input_fields.nom.test(condidatData.nom)) setNomValid(true);
    else setNomValid(false);
    if (!input_fields.nom.test(condidatData.adresse)) setAdresseValid(true);
    else setAdresseValid(false);
    if (!input_fields.age.test(condidatData.age)) setAgeValid(true);
    else setAgeValid(false);
    if (!input_fields.tel.test(condidatData.telephone)) setTelValid(true);
    else setTelValid(false);
    if (!input_fields.nom.test(condidatData.diplome)) setDiplomeValid(true);
    else setDiplomeValid(false);
    if (!input_fields.nom.test(condidatData.ecole)) setEcoleValid(true);
    else setEcoleValid(false);
    if (condidatData.situationFamiliale.length === 0) setSituationValid(true);
    else setSituationValid(false);
    if (condidatData.travailFrance.length === 0) setTravailValid(true);
    else setTravailValid(false);
    if (condidatData.mobilite.length === 0) setMobiliteValid(true);
    else setMobiliteValid(false);
    if (condidatData.contratFrance.length === 0) setContratValid(true);
    else setContratValid(false);
    if (condidatData.cv.length === 0) setCvValid(true);
    else setCvValid(false);
    if (
      !telValid &&
      !emailValid &&
      !nomValid &&
      !adresseValid &&
      !ageValid &&
      !ecoleValid &&
      !diplomeValid &&
      condidatData.email.length !== 0 &&
      condidatData.telephone.length !== 0 &&
      condidatData.nom.length !== 0 &&
      condidatData.adresse.length !== 0 &&
      condidatData.age.length !== 0 &&
      condidatData.ecole.length !== 0 &&
      condidatData.diplome.length !== 0 &&
      condidatData.situationFamiliale.length !== 0 &&
      condidatData.travailFrance.length !== 0 &&
      condidatData.mobilite.length !== 0 &&
      condidatData.contratFrance.length !== 0 &&
      condidatData.cv.length !== 0
    ) {
      setBtnForm(true);
      let formData = new FormData();
      formData.append("info", JSON.stringify(condidatData));
      formData.append("cv", condidatData.cv);
      formData.append("lettreMotivation", condidatData.lettreMotivation);
      axios
        .post("/postuler/candidature", formData)
        .then((res) => {
          setContactErreur(false);
          setTimeout(() => {
            setContactErreur(true);
          }, 3300);
          setCondidatData({
            ...condidatData,
            nom: "",
            adresse: "",
            age: "",
            email: "",
            telephone: "",
            diplome: "",
            ecole: "",
            entrepriseActuelle: "",
            equipeActuelle: "",
            domaineExpertise: "",
            typeMission: "",
            anneeExperience: "",
            technologies: "",
            certifications: "",
            entrepriseInternationales: "",
            projetInternationales: "",
            situationFamiliale: "",
            disponibilite: "",
            typeContrat: "",
            salaire: "",
            pretentionSalaire: "",
            travailFrance: "",
            mobilite: "",
            contratFrance: "",
            visaDate: "",
            visaType: "",
            cv: "",
            lettreMotivation: "",
          });
          document.getElementById("Célibataire").checked = false;
          document.getElementById("Marié avec enfant").checked = false;
          document.getElementById("Marié sans enfant").checked = false;
          document.getElementById("Oui").checked = false;
          document.getElementById("Non").checked = false;
          document.getElementById(
            "Je peux travailler et je suis mobile pour travailler dans toute la France"
          ).checked = false;
          document.getElementById("Juste Paris").checked = false;
          document.getElementById(
            "Ile de France (Grand Paris)"
          ).checked = false;
          document.getElementById("Autre ville").checked = false;
          document.getElementById(
            "Juste le titre bleu européen"
          ).checked = false;
          document.getElementById(
            "Je suis ouvert au Détachement"
          ).checked = false;
          document.getElementById("CDI").checked = false;
          document.getElementById("cv").value = "";
          document.getElementById("lettreMotivation").value = "";
          setBtnForm(false);
        })
        .catch((err) => {
          setContactErreur(true);
          setBtnForm(false);
        });
    } else setFormError(true);
  };
  return (
    <>
      {load && load.candidature ? (
        <div>
          <div className="candidature-welcome">
            <h2>{t("Candidature Spontanée.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/candidature">{t("Candidature Spontanée.1")}</Link>
            </div> */}
          </div>
          <div className="candidature-img">
            <div>
              <img src="/sources/candid-img.webp" loading="lazy" alt="candidature" />
            </div>
            <div>
              <h2>{t("DÉPOSER UNE CANDIDATURE SPONTANÉE.1")}</h2>
              <img src="/sources/cercle.webp" loading="lazy" alt="cercle" />
            </div>
          </div>
          <div className="titre-cand">
          {t("Vous souhaitez rejoindre un groupe ambitieux et à taille humaine où l’expertise se mêle avec l’esprit d’équipe, nous vous attendons.1")}
          </div>
          <div className=" form-candidature">
        <form className="p-5" onSubmit={onSubmit}>
          <label htmlFor="nom">{t("Nom et prénom.1")}*</label>
          <input
            type="text"
            id="nom"
            value={condidatData.nom}
            className="form-control mb-2"
            onChange={onChange}
            autoFocus
            style={{ border: nomValid && "1px solid red" }}
          />
          <label htmlFor="adresse">{t("Adresse.1")}*</label>
          <input
            type="text"
            id="adresse"
            value={condidatData.adresse}
            className="form-control mb-2"
            onChange={onChange}
            style={{ border: adresseValid && "1px solid red" }}
          />
          <label htmlFor="age">Age*</label>
          <input
            type="text"
            id="age"
            value={condidatData.age}
            className="form-control mb-2"
            onChange={onChange}
            style={{ border: ageValid && "1px solid red" }}
          />
          <label htmlFor="email">E-mail*</label>
          <input
            type="email"
            id="email"
            value={condidatData.email}
            className="form-control mb-2"
            onChange={onChange}
            style={{ border: emailValid && "1px solid red" }}
          />
          <label htmlFor="telephone">
            {t("Télephone.1")}* (ex:+33xxxxxxxxx)
          </label>
          <input
            type="tel"
            id="telephone"
            value={condidatData.telephone}
            className="form-control mb-2"
            onChange={onChange}
            style={{ border: telValid && "1px solid red" }}
          />
          <label htmlFor="diplome">{t("Diplome.1")}*</label>
          <input
            type="text"
            id="diplome"
            value={condidatData.diplome}
            className="form-control mb-2"
            onChange={onChange}
            style={{ border: diplomeValid && "1px solid red" }}
          />
          <label htmlFor="ecole">{t("Ecole.1")}*</label>
          <input
            type="text"
            id="ecole"
            value={condidatData.ecole}
            className="form-control mb-2"
            onChange={onChange}
            style={{ border: ecoleValid && "1px solid red" }}
          />
          <label htmlFor="entrepriseActuelle">
            {t("Actuellement vous travaillez dans quelle entreprise?.1")}
          </label>
          <input
            type="text"
            id="entrepriseActuelle"
            className="form-control mb-2"
            value={condidatData.entrepriseActuelle}
            onChange={onChange}
          />
          <label htmlFor="equipeActuelle">
            {t(
              "Dans votre entrerise actuelle, vous travaillez au sein de quelle équipe?.1"
            )}
          </label>
          <input
            type="text"
            id="equipeActuelle"
            className="form-control mb-2"
            value={condidatData.equipeActuelle}
            onChange={onChange}
          />
          <label htmlFor="domaineExpertise">
            {t("Quel est votre domaine d'expertise?.1")}
          </label>
          <input
            type="text"
            id="domaineExpertise"
            className="form-control mb-2"
            value={condidatData.domaineExpertise}
            onChange={onChange}
          />
          <label htmlFor="typeMission">
            {t("Type de mission recherchée.1")}
          </label>
          <input
            type="text"
            id="typeMission"
            className="form-control mb-2"
            value={condidatData.typeMission}
            onChange={onChange}
          />
          <label htmlFor="anneeExperience">
            {t("Nombre d'année d'expérience.1")}
          </label>
          <input
            type="text"
            id="anneeExperience"
            className="form-control mb-2"
            value={condidatData.anneeExperience}
            onChange={onChange}
          />
          <label htmlFor="technologies">
            {t(
              "Quelles sont les technologies/compétences que vous maîtrisez le plus?.1"
            )}
          </label>
          <input
            type="text"
            id="technologies"
            className="form-control mb-2"
            value={condidatData.technologies}
            onChange={onChange}
          />
          <label htmlFor="certifications">
            {t("Quelles sont les certifications que vous avez obtenues?.1")}
          </label>
          <input
            type="text"
            id="certifications"
            className="form-control mb-2"
            value={condidatData.certifications}
            onChange={onChange}
          />
          <label htmlFor="entrepriseInternationales">
            {t(
              "Avez-vous travaillé pour des entreprises internationales? Si c'est oui, merci de me préciser leurs noms.1"
            )}
          </label>
          <input
            type="text"
            id="entrepriseInternationales"
            className="form-control mb-2"
            value={condidatData.entrepriseInternationales}
            onChange={onChange}
          />
          <label htmlFor="projetInternationales">
            {t(
              "Avez-vous réalisé des projets pour le compte d'entreprises internationales? Si c'est oui, merci de me préciser leurs noms.1"
            )}
          </label>
          <input
            type="text"
            id="projetInternationales"
            className="form-control mb-2"
            value={condidatData.projetInternationales}
            onChange={onChange}
          />
          <label htmlFor="situationFamiliale" style={{ display: "block" }}>
            {t("Votre situation familiale.1")}*
          </label>
          <div
            style={{
              border: situationValid && "1px solid red",
              borderRadius: ".25rem",
            }}
            className="mb-2 p-1"
          >
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Célibataire"
                name="situationFamiliale"
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor="Célibataire">
                {t("Célibataire.1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Marié avec enfant"
                name="situationFamiliale"
                onChange={onChange}
              />
              <label
                className="custom-control-label"
                htmlFor="Marié avec enfant"
              >
                {t("Marié avec enfant.1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Marié sans enfant"
                name="situationFamiliale"
                onChange={onChange}
              />
              <label
                className="custom-control-label"
                htmlFor="Marié sans enfant"
              >
                {t("Marié sans enfant.1")}
              </label>
            </div>
          </div>
          <label htmlFor="disponibilite">{t("Votre disponibilité.1")}</label>
          <input
            type="text"
            id="disponibilite"
            className="form-control mb-2"
            value={condidatData.disponibilite}
            onChange={onChange}
          />
          <label htmlFor="typeContrat">
            {t(
              "Actuellement, avez-vous quel type de contrat? CDD, CDI, Freelance ou Sans contrat?.1"
            )}
          </label>
          <input
            type="text"
            id="typeContrat"
            className="form-control mb-2"
            value={condidatData.typeContrat}
            onChange={onChange}
          />
          <label htmlFor="salaire">{t("Votre salaire net mensuel.1")}</label>
          <input
            type="text"
            id="salaire"
            className="form-control mb-2"
            value={condidatData.salaire}
            onChange={onChange}
          />
          <label htmlFor="pretentionSalaire">
            {t("Vos prétentions salariales (en Net).1")}
          </label>
          <input
            type="text"
            id="pretentionSalaire"
            className="form-control mb-2"
            value={condidatData.pretentionSalaire}
            onChange={onChange}
          />
          <label htmlFor="travailFrance" style={{ display: "block" }}>
            {t("Disponible pour travailler en France?.1")}*
          </label>
          <div
            style={{
              border: travailValid && "1px solid red",
              borderRadius: ".25rem",
            }}
            className="mb-2 p-1"
          >
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Oui"
                name="travailFrance"
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor="Oui">
                {t("Oui.1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Non"
                name="travailFrance"
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor="Non">
                {t("Non.1")}
              </label>
            </div>
          </div>
          <label htmlFor="mobilite" style={{ display: "block" }}>
            {t("Quelle est votre mobilité?.1")}*
          </label>
          <div
            style={{
              border: mobiliteValid && "1px solid red",
              borderRadius: ".25rem",
            }}
            className="mb-2 p-1"
          >
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Je peux travailler et je suis mobile pour travailler dans toute la France"
                name="mobilite"
                onChange={onChange}
              />
              <label
                className="custom-control-label"
                htmlFor="Je peux travailler et je suis mobile pour travailler dans toute la France"
              >
                {t(
                  "Je peux travailler et je suis mobile pour travailler dans toute la France.1"
                )}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Juste Paris"
                name="mobilite"
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor="Juste Paris">
                {t("Juste Paris.1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Ile de France (Grand Paris)"
                name="mobilite"
                onChange={onChange}
              />
              <label
                className="custom-control-label"
                htmlFor="Ile de France (Grand Paris)"
              >
                {t("Ile de France (Grand Paris).1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Autre ville"
                name="mobilite"
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor="Autre ville">
                {t("Autre ville.1")}
              </label>
            </div>
          </div>

          <label htmlFor="contratFrance" style={{ display: "block" }}>
            {t(
              "Quel type de contrat vous cherchez pour aller travailler en France?.1"
            )}
            *
          </label>
          <div
            style={{
              border: contratValid && "1px solid red",
              borderRadius: ".25rem",
            }}
            className="mb-2 p-1"
          >
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Juste le titre bleu européen"
                name="contratFrance"
                onChange={onChange}
              />
              <label
                className="custom-control-label"
                htmlFor="Juste le titre bleu européen"
              >
                {t("Juste le titre bleu européen.1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="Je suis ouvert au Détachement"
                name="contratFrance"
                onChange={onChange}
              />
              <label
                className="custom-control-label"
                htmlFor="Je suis ouvert au Détachement"
              >
                {t("Je suis ouvert au Détachement.1")}
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                className="custom-control-input"
                id="CDI"
                name="contratFrance"
                onChange={onChange}
              />
              <label className="custom-control-label" htmlFor="CDI">
                CDI
              </label>
            </div>
          </div>
          <label htmlFor="visaDate">
            {t(
              "Si vous avez un visa, le visa est valide jusqu'à quelle date?.1"
            )}
          </label>
          <input
            type="date"
            id="visaDate"
            className="form-control mb-2"
            value={condidatData.visaDate}
            onChange={onChange}
          />
          <label htmlFor="visaType">
            {t(
              "Avez-vous un VISA ? Quel type ? Touristique, Détaché, ou visa d’affaires?.1"
            )}
          </label>
          <input
            type="text"
            id="visaType"
            className="form-control mb-2"
            value={condidatData.visaType}
            onChange={onChange}
          />
          <label htmlFor="cv">{t("Votre CV.1")}*</label>
          <input
            type="file"
            accept="application/pdf"
            id="cv"
            name="cv"
            className="form-control mb-2"
            style={{
              fontSize: 15,
              padding: "0.2rem 0.5rem",
              border: cvValid && "1px solid red",
            }}
            onChange={onChange}
          />
          <label htmlFor="lettre">{t("Lettre de motivation.1")}</label>
          <input
            type="file"
            accept="application/pdf"
            id="lettreMotivation"
            name="lettreMotivation"
            className="form-control mb-2"
            style={{ fontSize: 15, padding: "0.2rem 0.5rem" }}
            onChange={onChange}
          />

          <div
            style={{
              width: "100%",
              textAlign: "start",
              color: "gray",
              marginBottom: 10,
            }}
          >
            *{t("Champs requis.1")}
          </div>
          <button
            disabled={btnForm}
            className="btn  btn-block"
            type="submit"
            style={{ background: "white" }}
          >
            {t("Envoyer.1")}
          </button>
        </form>

        <div className="erreur-form" style={{ display: !formError && "none" }}>
          <p>
            {t("Un ou plusieurs champs contiennent une erreur.1")}.{" "}
            {t("Veuillez vérifier et essayer à nouveau.1")}
          </p>
        </div>
      </div>
      <div> <img src="/sources/ellipse4.webp" loading="lazy" alt="ellipse"/></div>
      <div
        className="contact-alert"
        style={{
          opacity: contactErreur && 0,
          visibility: contactErreur && "hidden",
        }}
      >
        {t("Votre candidature a été envoyé, merci de votre intérêt.1")}
      </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Candidature;
