import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const fallbackLng = ['fr'];
const availableLanguages = ['fr', 'en'];

// const options = {
//   // order and from where user language should be detected
//   order: [ 'navigator', 'htmlTag', 'path', 'subdomain'],

//   // keys or params to lookup language from
//   lookupQuerystring: 'lng',
//   lookupCookie: 'i18next',
//   lookupLocalStorage: 'i18nextLng',
//   lookupFromPathIndex: 0,
//   lookupFromSubdomainIndex: 0,

//   // cache user language on
//   caches: ['localStorage', 'cookie'],
//   excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  
//   // only detect languages that are in the whitelist
//   checkWhitelist: true
// }


i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false,
    whitelist: availableLanguages,
    // detection: options,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;