import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../style/changePw.css";
import { GET_SUCCESS_PASSWORD, GET_ERRORS_PASSWORD } from "../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { updatePassWord } from "./../actions/authaction";

function ChangePW() {
  const { t } = useTranslation();
  const errors = useSelector((state) => state.errors.errorMsg);
  const successes = useSelector((state) => state.success.password_success);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    currentPassword: "",
    password: "",
    password2: "",
    error: {},
  });
  const [currentpasstype, setCurrentpasstype] = useState("password");
  const [passtype, setpasstype] = useState("password");
  const [passvertype, setpassvertype] = useState("password");
  // const [offreErreur, setOffreErreur] = useState(true);
  useEffect(() => {
    if (errors) setUser({ ...user, error: errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {}, [successes]);
  const onChange = (e) => {
    setUser({ ...user, [e.target.id]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (user.password !== user.password2) {
      setUser({ ...user, error: { password2: true } });
    } else if (user.password === user.currentPassword) {
      setUser({ ...user, error: { password: true } });
    } else {
      dispatch(updatePassWord(user));
    }
  };

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS_PASSWORD,
          payload: null,
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (successes) {
      setTimeout(() => {
        dispatch({
          type: GET_SUCCESS_PASSWORD,
          payload: null,
        });
        setUser({
          currentPassword: "",
          password: "",
          password2: "",
          error: {},
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  return (
    <>
    <div
            className="tab"
            style={{ position: "relative" }}
            
          >
      <div style={{ margin: "3% 15% 1% 15%" }}>
        <div>
          <div className="add-user">
            <h2>{t("ChangePW.2")}</h2>
          </div>

          <form onSubmit={onSubmit}>
            <div className="form-outline mb-4 mp-form">
              <label className="form-label" htmlFor="password">
                {t("Nouveau mot de passe.2")}
              </label>
              <input
                onChange={onChange}
                required
                value={user.currentPassword}
                id="currentPassword"
                type={currentpasstype}
                style={{ paddingRight: 25, boxSizing: "border-box" }}
                className="form-control"
              />

              <span className={user.error.password && "red-text"}>
                {user.error.password ? (
                  <span>{t("Nouveau mot de passe.4")}</span>
                ) : null}
              </span>

              <span
                onClick={() => {
                  if (currentpasstype === "password")
                    setCurrentpasstype("text");
                  else setCurrentpasstype("password");
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 39,
                  color: "gray",
                }}
                title={
                  currentpasstype === "password"
                    ? "Show password"
                    : "Hide password"
                }
              >
                {currentpasstype === "password" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>

            <div className="form-outline mb-4 mp-form">
              <label className="form-label" htmlFor="password">
                {t("Nouveau mot de passe.1")}
              </label>
              <input
                onChange={onChange}
                value={user.password}
                required
                id="password"
                type={passtype}
                style={{ paddingRight: 25, boxSizing: "border-box" }}
                className="form-control"
              />

              <span className={user.error.password && "red-text"}>
                {user.error.password ? (
                  <span>{t("Nouveau mot de passe.4")}</span>
                ) : null}
              </span>
              <span
                onClick={() => {
                  if (passtype === "password") setpasstype("text");
                  else setpasstype("password");
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 39,
                  color: "gray",
                }}
                title={
                  passtype === "password" ? "Show password" : "Hide password"
                }
              >
                {passtype === "password" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="form-outline mb-4 mp-form">
              <label className="form-label" htmlFor="password2">
                {t("Confirmer le nouveau mot de passe.1")}
              </label>
              <input
                onChange={onChange}
                value={user.password2}
                required
                id="password2"
                type={passvertype}
                style={{ paddingRight: 25, boxSizing: "border-box" }}
                className="form-control"
              />

              <span className={user.error.password2 && "red-text"}>
                {user.error.password2 ? (
                  <span>{t("Nouveau mot de passe.3")}</span>
                ) : null}
              </span>
              <span
                onClick={() => {
                  if (passvertype === "password") setpassvertype("text");
                  else setpassvertype("password");
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 39,
                  color: "gray",
                }}
                title={
                  passvertype === "password" ? "Show password" : "Hide password"
                }
              >
                {passvertype === "password" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div style={{ paddingLeft: "11.250px" }}>
              <button
                style={{
                  width: "fit-content",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  margin: "auto auto",
                  height: "45px",
                }}
                type="submit"
                className="btn btn-primary btn-block "
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
      {successes ? (
        <div
          className="ch-alert"
          style={{
            opacity: successes ? 100 : 0,
            visibility: successes ? "visible" : "hidden",
          }}
        >
          Mot de passe changé avec succés
        </div>
      ) : null}

      {errors ? (
        <div
          className="ch-alert"
          style={{
            opacity: errors ? 100 : 0,
            visibility: errors ? "visible" : "hidden",
            background: "red",
          }}
        >
          {errors}
        </div>
      ) : null}
    </>
  );
}

export default ChangePW;
