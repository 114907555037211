import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../style/tableau.css";
import Conge from "./Conge";
import Autorisation from "./Autorisation";
import AutoList from "./AutoList";
import CongeList from "./CongeList";

function Tableau() {
  const [selection, setSelection] = useState("");
  const { t } = useTranslation();

  const returnSection = () => {
    document.querySelector(".tab").classList.remove("slide");
    setTimeout(() => {
      setSelection("");
    }, 1200);
  };

  return (
    <div className="tab">
      <div className="map-tab">
        <div
          onClick={() => {
            selection === "conge" ? setSelection("") : setSelection("conge");
            document.querySelector(".tab").classList.add("slide");
          }}
          style={{
            border: selection === "conge" && "2px solid #f46485",
            boxShadow: selection === "conge" && "none",
          }}
        >
          <img src="/sources/ESP3.webp" loading="lazy" alt="dash" />
          <h3>{t("Demande de congé.1")}</h3>
        </div>
        <div
          onClick={() => {
            selection === "list" ? setSelection("") : setSelection("list");
            document.querySelector(".tab").classList.add("slide");
          }}
          style={{
            border: selection === "list" && "2px solid #eaa369",
            boxShadow: selection === "list" && "none",
          }}
        >
          <img src="/sources/ESP4.webp" loading="lazy" alt="dash" />
          <h3>{t("Liste de congé.1")}</h3>
        </div>
        <div
          onClick={() => {
            selection === "autorisation"
              ? setSelection("")
              : setSelection("autorisation");
            document.querySelector(".tab").classList.add("slide");
          }}
          style={{
            border: selection === "autorisation" && "2px solid #6752b7",
            boxShadow: selection === "autorisation" && "none",
          }}
        >
          <img src="/sources/ESP1.webp" loading="lazy" alt="dash" />
          <h3>{t("Demande d'autorisation.1")}</h3>
        </div>
        <div
          onClick={() => {
            selection === "autoList"
              ? setSelection("")
              : setSelection("autoList");
            document.querySelector(".tab").classList.add("slide");
          }}
          style={{
            border: selection === "autoList" && "2px solid #30ab92",
            boxShadow: selection === "autoList" && "none",
          }}
        >
          <img src="/sources/ESP2.webp" loading="lazy" alt="dash" />
          <h3 style={{ textAlign: "center" }}>{t("Liste d'autorisation.1")}</h3>
        </div>
      </div>
      <div className="tab-slide">
        <div>
          <img
            src="/sources/arrow.svg"
            loading="lazy"
            alt="back"
            style={{ cursor: "pointer", marginBottom: 20 }}
            onClick={returnSection}
          />
        </div>
        {selection === "conge" && <Conge returnSection={returnSection} />}
        {selection === "list" && <CongeList />}
        {selection === "autorisation" && (
          <Autorisation returnSection={returnSection} />
        )}
        {selection === "autoList" && <AutoList />}
        
      </div>
    </div>
  );
}

export default Tableau;
