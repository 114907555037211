import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../style/competence.css";
import { COMPETENCE } from "../actions/types";
function Competence() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.competence &&
        dispatch({
          type: COMPETENCE,
        });
    };
    !load.competence && (img.src = "/sources/competence2.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.competence ? (
        <div>
          <div className="comp-welcome">
            <h2>{t("Nos Compétences.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/competence">{t("Nos Compétences.1")}</Link>
            </div> */}
          </div>
          <img src="/sources/competence2.webp" alt="competence" />
          <div className="comp-para">
            <h2>{t("Nos Compétences, Notre Expertise.1")}</h2>
            <div>
              {t("L’équipe.1")} <span>VIBTIC</span>{" "}
              {t(
                "se compose d’analystes, d’une véritable expertise technique, de développeurs d’applications et d’infrastructure réseau et sécurité, hautement qualifiés et issus des bonnes universités et écoles pour répondre avec précision à vos besoins, nous mettons le meilleur de nos compétences à votre service.1"
              )}
              . <br />
              {t(
                "Le champ d'action de nos solutions est ainsi le plus complet possible.1"
              )}
              . <br />
              <span>VIBTIC</span>{" "}
              {t(
                "s’est engagée dans une démarche de qualité pour accompagner les projets de ses clients en mettant en place des partenariats consultatifs, en formant des équipes dédiées avec un point de contact unique.1"
              )}
              . <br />
              {t(
                "Aujourd’hui nos équipes d’experts mènent à bien leurs différentes missions de conseil avec un fort niveau d’exigence et de rigueur.1"
              )}
              . <br />
              {t("Notre.1")}
              <span> expertise</span>{" "}
              {t(
                "dans le domaine de l’informatique et de la maintenance est un atout de poids, nous misons sur nos connaissances, notre expertise et nos produits de qualité pour mieux servir notre clientèle.1"
              )}
              .
            </div>
          </div>
          <div>
            {" "}
            <img src="/sources/ellipse4.webp" alt="ellipse" />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Competence;
