import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../actions/authaction";
import { CONNEXION, GET_ERRORS, GET_ERRORS_CNX } from "../actions/types";
import { useTranslation } from "react-i18next";
import "../style/login.css";
import { Link } from "react-router-dom";

function Login({ history }) {
  const load = useSelector((state) => state.load);
  const errors = useSelector((state) => state.errors.errors);
  const cnx_errors = useSelector((state) => state.errors.cnx_errors);
  const dispatch = useDispatch();
  const [passtype, setpasstype] = useState("password");
  const [userlog, setUserlog] = useState({
    email: "",
    password: "",
    error: {},
  });
  const [size, setSize] = useState(false);
  const [offreErreur, setOffreErreur] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.connexion &&
        dispatch({
          type: CONNEXION,
        });
    };
    !load.connexion && (img.src = "/sources/login-img.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.token) history.push("/moncompte");
  });

  useEffect(() => {
    dispatch({
      type: GET_ERRORS,
      payload: null,
    });
    dispatch({
      type: GET_ERRORS_CNX,
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 3000);
    }
    if (cnx_errors) {
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS_CNX,
          payload: null,
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, cnx_errors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (cnx_errors && cnx_errors.blocage) {
      setOffreErreur(false);
      setTimeout(() => {
        setOffreErreur(true);
      }, 3300);

      dispatch({
        type: GET_ERRORS_CNX,
        payload: null,
      });
    }
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 991) {
        setSize(true);
      } else {
        setSize(false);
      }
    });
  });

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setSize(true);
    }
  }, []);

  useEffect(() => {
    if (cnx_errors) setUserlog({ ...userlog, error: cnx_errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnx_errors]);

  const { t } = useTranslation();

  const onChange = (e) => {
    setUserlog({ ...userlog, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // dispatch({
    //   type: GET_ERRORS_CNX,
    //   payload: null,
    // });
    const userData = {
      email: userlog.email.toLowerCase(),
      password: userlog.password,
    };
    dispatch(loginUser(userData, history));
    setUserlog({ ...userlog, error: {} });
  };
  return (
    <>
      {load && load.connexion ? (
        <div>
          <div className="login-bar">
            <h2>{t("Mon espace.1")}</h2>
            <img src="/sources/login-icon.webp" loading="lazy" alt="connect" />
          </div>
          <div className="img-div">
            <img
              src={size ? "/sources/login-img2.webp" : "/sources/login-img.webp"}
              alt="login"
              loading="lazy"
            />
            <p>
              {t(
                "Veuillez saisir votre login et mot de passe pour accéder à votre espace.1"
              )}
            </p>
          </div>
          <div className="input-form">
            <div>
              <form onSubmit={onSubmit}>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    onChange={onChange}
                    value={userlog.email}
                    autoFocus
                    style={{ backgroundColor: "white" }}
                  />
                  <span className={userlog.error.email && "red-text"}>
                    {userlog.error.email}
                  </span>
                </div>

                <div className="form-outline mb-4 mp-form">
                  <label className="form-label" htmlor="password">
                    {t("Mot de passe.1")}
                  </label>
                  <input
                    id="password"
                    className="form-control"
                    onChange={onChange}
                    value={userlog.password}
                    type={passtype}
                    style={{
                      paddingRight: 25,
                      boxSizing: "border-box",
                      backgroundColor: "white",
                    }}
                  />
                  <span className={userlog.error.password && "red-text"}>
                    {userlog.error.password}
                  </span>
                  <span
                    onClick={() => {
                      if (passtype === "password") setpasstype("text");
                      else setpasstype("password");
                    }}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 39,
                      color: "gray",
                    }}
                    title={
                      passtype === "password"
                        ? "Show password"
                        : "Hide password"
                    }
                  >
                    {passtype === "password" ? (
                      <i className="far fa-eye"></i>
                    ) : (
                      <i className="fas fa-eye-slash"></i>
                    )}
                  </span>
                </div>
                <div className="form-outline mb-4">
                  <Link to="/recover">{t("Se connecter.2")}</Link>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  style={{ width: 170, margin: "0px auto", borderRadius: 5 }}
                >
                  {t("Se connecter.1")}
                </button>
              </form>
            </div>
          </div>
          <div>
            {" "}
            <img src="/sources/ellipse4.webp" loading="lazy" alt="ellipse" />
          </div>
          <div
            className="err-alert"
            style={{
              opacity: offreErreur && 0,
              visibility: offreErreur && "hidden",
              backgroundColor: "red",
            }}
          >
            {t("Accès refusé.1")}
          </div>
          {errors ? (
            <div
              className="account-alert"
              style={{
                opacity: 100,
                visibility: "visible",
                background: "red",
              }}
            >
              {errors}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Login;
