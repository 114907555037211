import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../actions/authaction";
import { GET_SUCCESS, GET_ERRORS } from "../actions/types";
import { useTranslation } from "react-i18next";
import "../style/account.css";

function Account({ setSelection }) {
  const errors = useSelector((state) => state.errors.errors);
  const successes = useSelector((state) => state.success.success);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation();
  const [user, setUser] = useState({
    address: "",
    email: "",
    fname: "",
    fonction: "",
    lname: "",
    tel: "",
    error: {},
  });
  const [confirmationInput, setConfirmationInput] = useState({
    confirm: "",
    error: "",
  });
  const [mod, setMod] = useState(false);
  const [contactErreur, setContactErreur] = useState(true);
  const [contactErreur1, setContactErreur1] = useState(true);
  const [avatar, setAvatar] = useState();
  const [btn, setBtn] = useState(false);

  const onChange = (e) => {
    if (e.target.id === "avatar") {
      setUser({ ...user, [e.target.id]: e.target.files[0] });
      setAvatar(URL.createObjectURL(e.target.files[0]));
    } else setUser({ ...user, [e.target.id]: e.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setBtn(true);
    let formData = new FormData();
    formData.append("info", JSON.stringify(user));
    if (user.avatar) {
      formData.append("avatar", user.avatar);
    }
    dispatch(updateUser(formData));
  };

  useEffect(() => {
    if (successes) {
      setTimeout(() => {
        dispatch({
          type: GET_SUCCESS,
          payload: null,
        });
        setSelection("bord");
      }, 3000);
    }
  }, [successes]);

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        setBtn(false);
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 3000);
    }
  }, [errors]);

  useEffect(() => {
    dispatch({
      type: GET_SUCCESS,
      payload: null,
    });
    dispatch({
      type: GET_ERRORS,
      payload: null,
    });
  }, []);

  useEffect(() => {
    if (auth && auth.user) {
      user.email = auth.user.email;
      user.fname = auth.user.fname;
      user.fonction = auth.user.fonction;
      user.lname = auth.user.lname;
      user.tel = auth.user.tel;
      user.address = auth.user.address;
      setUser(auth.user);
    }
  }, [auth]);
  useEffect(() => {
    if (!localStorage.token) history.push("/connexion");
  });
  useEffect(() => {
    if (errors) {
      setConfirmationInput({ ...confirmationInput, error: errors });
      setBtn(false);
      setMod(!mod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // useEffect(() => {
  //   if (errors.success) {
  //     onSubmit();
  //     dispatch({ type: GET_ERRORS, payload: {} });
  //   }
  // });

  useEffect(() => {
    if (errors) {
      setUser({ ...user, error: errors });
      setBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (errors && errors.update) {
  //     setContactErreur(false);
  //     setTimeout(() => {
  //       setContactErreur(true);
  //     }, 3300);
  //     setUser({
  //       ...user,
  //       tel: "",
  //       address: "",
  //       avatar: "",
  //     });
  //     setBtn(false);
  //     document.getElementById("avatar").value = "";
  //     dispatch({ type: GET_ERRORS, payload: null });
  //   }
  // });

  return (
    <>
      {auth && auth.user ? (
        <>
          <div
            className="tab"
            style={{ position: "relative" }}
            onClick={(e) => {
              if (
                e.target !== document.querySelectorAll(".custom_mod") &&
                mod
              ) {
                setMod(!mod);
                btn && setBtn(false);
              }
            }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <h3>
                  <b>{t("Modifier votre compte.1")}</b>
                </h3>
              </div>

              <div
                style={{
                  transform: "translateY(10px)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={avatar ? avatar : auth?.user?.avatar}
                    alt="avatar"
                    height="150px"
                    width="150px"
                    style={{
                      borderRadius: "50%",
                      opacity: avatar && "0.3",
                    }}
                  />
                  <div className="file-img">
                    <label htmlFor="avatar" title="changer la photo du profil">
                      <i className="fas fa-camera"></i>
                    </label>
                    <input
                      id="avatar"
                      name="avatar"
                      className="form-control mb-2"
                      type="file"
                      accept="image/*"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>

              <form
                encType="multipart/form-data"
                className="mt-4"
                onSubmit={onSubmit}
              >
                <div className="form-outline mb-4">
                  <label htmlFor="first_name" className="green-text">
                    {t("Prénom.1")}
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={user?.fname}
                    id="fname"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-outline mb-4">
                  <label htmlFor="last_name" className="green-text">
                    {t("Nom.1")}
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={user?.lname}
                    id="lname"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-outline mb-4">
                  <label htmlFor="email" className="green-text">
                    E-mail
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={user?.email}
                    id="email"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-outline mb-4">
                  <label htmlFor="fonction" className="green-text">
                    {t("Fonction.1")}
                  </label>
                  <input
                    disabled
                    value={auth?.user?.fonction}
                    id="fonction"
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="form-outline mb-4">
                  <label htmlFor="tel" className="active">
                    {t("Téléphone.1")}
                  </label>
                  <input
                    required
                    onChange={onChange}
                    value={user.tel}
                    id="tel"
                    type="tel"
                    className="form-control"
                  />

                  <span className={user.error ? "red-text" : "green-text"}>
                    {user.error?.tel ||
                      (auth.user.tel &&
                        t("Votre numéro est.1") + " : " + auth.user.tel)}
                  </span>
                </div>
                <div className="form-outline mb-4">
                  <label htmlFor="address">{t("Adresse.1")}</label>
                  <input
                    required
                    onChange={onChange}
                    value={user.address}
                    id="address"
                    type="text"
                    className="form-control"
                  />

                  <span className={user.error ? "red-text" : "green-text"}>
                    {user.error?.address ||
                      (auth.user.address &&
                        t("Votre adresse est.1") + " : " + auth.user.address)}
                  </span>
                </div>

                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button disabled={btn} className="btn btn-acc" type="submit">
                    {t("Mettre à jour.1")}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {successes ? (
            <div
              className="account-alert"
              style={{
                opacity: 10,
                visibility: "visible",
              }}
            >
              {t("Votre compte a été modifié avec succès.1")}
            </div>
          ) : null}
          {errors ? (
            <div
              className="account-alert"
              style={{
                opacity: 10,
                visibility: "visible",
                background: "red",
              }}
            >
              {errors}
            </div>
          ) : null}
        </>
      ) : null}

      
    </>
  );
}

export default Account;
