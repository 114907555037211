import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { demandeAuto } from "../actions/authaction";
import { GET_ERRORS_DEMANDE, GET_SUCCESS_DEMANDE } from "../actions/types";
import { useTranslation } from "react-i18next";

function Autorisation({ returnSection }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors.demande);
  const successes = useSelector((state) => state.success.demande_success);
  const [btnForm, setBtnForm] = useState(false);
  const [congeData, setCongeData] = useState({
    raison: "",
  });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = (e) => {
    setCongeData({ ...congeData, [e.target.id]: e.target.value });
    // setErrData({ ...errData, [e.target.id]: false });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnForm(true);
    dispatch(demandeAuto({ ...congeData, userId: auth.user._id }));
    
  };

  useEffect(() => {
    if (successes) {
      setTimeout(() => {
        dispatch({
          type: GET_SUCCESS_DEMANDE,
          payload: null,
        });
        setCongeData({
          raison: "",
        });
        returnSection();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        setBtnForm(false);
        dispatch({
          type: GET_ERRORS_DEMANDE,
          payload: "",
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
 
  useEffect(()=>{
    dispatch({
      type: GET_ERRORS_DEMANDE,
      payload: "",
    });
    dispatch({
      type: GET_SUCCESS_DEMANDE,
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="tab-conge">
      <div>
        <h3 style={{ textAlign: "center" }}>{t("Demande d'autorisation.1")}</h3>
        <form className=" p-4" onSubmit={onSubmit}>
          {auth.user.fname && (
            <div className="form-outline mb-4">
              <label htmlFor="first_name" className="green-text">
                {t("Prénom.1")}
              </label>
              <input
                disabled
                value={auth.user.fname}
                id="first_name"
                type="text"
                className="form-control"
              />
            </div>
          )}
          {auth.user.lname && (
            <div className="form-outline mb-4">
              <label htmlFor="last_name" className="green-text">
                {t("Nom.1")}
              </label>
              <input
                disabled
                value={auth.user.lname}
                id="last_name"
                type="text"
                className="form-control"
              />
            </div>
          )}
          {auth.user.email && (
            <div className="form-outline mb-4">
              <label htmlFor="email" className="green-text">
                E-mail
              </label>
              <input
                disabled
                value={auth.user.email}
                id="email"
                type="text"
                className="form-control"
              />
            </div>
          )}
          {auth.user.fonction && (
            <div className="form-outline mb-4">
              <label htmlFor="fonction" className="green-text">
                {t("Fonction.1")}
              </label>
              <input
                disabled
                value={auth.user.fonction}
                id="fonction"
                type="text"
                className="form-control"
              />
            </div>
          )}

          <label>{t("Raison.1")}*</label>
          <textarea
            type="text"
            id="raison"
            rows={4}
            required
            className="form-control mb-4"
            value={congeData.raison}
            onChange={onChange}
          />

          <button
            disabled={btnForm}
            className="btn  btn-block"
            type="submit"
            style={{ background: "#464646", color: "white" }}
          >
            {t("Envoyer.1")}
          </button>
        </form>
      </div>

      {successes ? (
        <div
          className="conge-alert"
          style={{
            opacity: successes ? 100 : 0,
            visibility: successes ? "visible" : "hidden",
          }}
        >
          {t("Votre demande a été envoyé avec succès.1")}
        </div>
      ) : null}

      {errors ? (
        <div
          className="conge-alert"
          style={{
            opacity: errors ? 100 : 0,
            visibility: errors ? "visible" : "hidden",
            background: "red",
            padding: "1%"
          }}
        >
          {errors}
        </div>
      ) : null}

      
    </div>
  );
}

export default Autorisation;
