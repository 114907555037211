import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Decrypt from "./Decrypt";
import "../../../style/AnswerItem.css"
const AnswerItem = (props) => {
  const dispatch = useDispatch();
  const [proposition, setProposition] = useState();

  useEffect(() => {}, [props]);

  useEffect(() => {}, [proposition]);

  const handleSelect = (res, indx) => {
    setProposition({ ...proposition, response: !res });

    let answer = {
      proposition: props.proposition.proposition,
      response: !res,
      index: props.index,
    };
    dispatch(props.quizAnswerClick(answer));
  };
  useEffect(() => {}, [proposition]);

  return (
    <>
      {props.proposition && (
        <li
          id={props.proposition.index}
          key={"proposi"+props.proposition.index}
          className={
            props.proposition.response ? "AnswerItemSuccess" : "AnswerItem"
          }
          onClick={() =>
            handleSelect(props.proposition.response, props.proposition.index)
          }
        >
          {props.proposition && Decrypt(props.proposition.proposition)}
        </li>
      )}
    </>
  );
};

export default AnswerItem;
