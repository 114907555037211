import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/projet.css";
import { CLOUD } from "../actions/types";
function Cloud() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.cloud &&
        dispatch({
          type: CLOUD,
        });
    };
    !load.cloud && (img.src = "/sources/cloud-msg.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.cloud ? (
        <div>
          <div className="projet-welcome">
            <h2>{t("Solution CLOUD.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/cloud">{t("Solution CLOUD.1")}</Link>
            </div> */}
          </div>
          <div className="projet-msg">
            <div>
              <h2 style={{ color: "#dab64a" }}>{t("Solution CLOUD.1")}</h2>
              <p>
                {t(
                  "La situation actuelle pousse les DSI à accélérer la transformation de leur système.1"
                )}
                .{" "}
                {t(
                  "Mais ces projets sont complexes, et le cloud n’est pas qu’un sujet technologique.1"
                )}
                .
              <br/>
                {t(
                  "Il est avant tout question de modèles organisationnels, opérationnels et économiques Il est primordial d’être correctement accompagné pour réussir sa transformation, réduire ses coûts, gagner en agilité et innover.1"
                )}
              </p>
            </div>
            <div>
              <img src="/sources/cloud-msg.webp" loading="lazy" alt="cloud" />
            </div>
          </div>
          <div className="projet-para">
            <h2>{t("Excellence Rapidité Décarbonation Rationalisation.1")}</h2>
            <div>
              <p>
                {t(
                  "De nouvelles questions se posent alors sur la manière de fournir les services (rapidité, sécurité, disponibilité, fiabilité, etc) ainsi que sur la manière dont seront traitées les données métiers ou clients.1"
                )}
                .
              <br/>
                {t(
                  "Les providers cloud s’adaptent à la demande, et proposent de plus en plus de nouveaux services (NaaS, StaaS, SECaaS, BPaaS, etc) parmi lesquels il est facile de se perdre.1"
                )}
                .
              <br/>
                {t(
                  "Forte de son expérience et de l’expertise de ses consultants certifiés sur les acteurs majeurs de Cloud Public, VIBTIC accompagne ses clients dans l’identification de leurs besoins, qu’ils soient règlementaires (cf RGPD), organisationnels ou techniques Nous les conseillons sur une solution adaptée et intervenons dans l’élaboration de leurs processus de transformation (de la conception, à la réalisation) en vue d’une externalisation totale ou partielle vers le cloud.1"
                )}
                .
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Cloud;
