import React, { useEffect } from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";
import "./CountdowTimer.css";

const ShowCounter = ({ hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <span className="countdown-link">
        {hours ? (
          <>
            <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
            <p>:</p>
          </>
        ) : null}

        {minutes ? (
          <>
            <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
            <p>:</p>
          </>
        ) : null}

        {seconds ? (
          <>
            <DateTimeDisplay
              value={seconds}
              type={"Seconds"}
              isDanger={minutes === 0 && seconds <= 15}
            />
          </>
        ) : null}
      </span>
    </div>
  );
};

const CountdownTimer = ({ targetDate, onHandleNext }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);

  useEffect(() => {
    if (hours + minutes + seconds === 0) {
      onHandleNext();
    }
  }, [hours, minutes, seconds]);
  return (
    <>
      {hours + minutes + seconds > 0 ? (
        <ShowCounter hours={hours} minutes={minutes} seconds={seconds} />
      ) : null}
    </>
  );
};

export default CountdownTimer;
