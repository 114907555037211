import {GET_CONGE} from "../actions/types";


  const initialState = {
    conge:[]
  };
  export default function conge(state = initialState, action) {
    switch (action.type) {
      case GET_CONGE:
        return {
          ...state,
         conge:action.payload
        };
      default:
        return state;
    }
  }