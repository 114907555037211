import axios from "axios";
import setAuthToken from "../token/authtoken";
import {
  GET_ERRORS_PASSWORD,
  GET_ERRORS,
  GET_SUCCESS,
  SET_CURRENT_USER,
  GET_CONGE,
  GET_AUTO,
  GET_SUCCESS_PASSWORD,
  GET_ERRORS_CONGE,
  GET_SUCCESS_CONGE,
  GET_SUCCESS_DEMANDE,
  GET_ERRORS_DEMANDE,
  GET_ERRORS_CNX,
} from "./types";

// confirm password
// export const confirmPassword = (pass) => (dispatch) => {
//   setAuthToken(localStorage.token);
//   axios
//     .post("/user/confirmation", pass)
//     .then((res) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//     });
// };

// Update User
export const updateUser = (userData) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put("/user/update", userData)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
      dispatch({
        type: GET_SUCCESS,
        payload: "Votre compte a été modifié avec succès",
      });
      // dispatch(getCurrentUser());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update password
export const updatePassWord = (userData) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put("/user/updatePassword", userData)
    .then((res) => {
      dispatch({
        type: GET_SUCCESS_PASSWORD,
        payload: res.data,
      });
      // dispatch(getCurrentUser());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS_PASSWORD,
        payload: err.response.data,
      })
    );
};

export const forgetPassword = (data) => (dispatch) => {
  axios
    .put("/user/forgetPassword", data)
    .then((res) => {
      dispatch({
        type: GET_SUCCESS_PASSWORD,
        payload: res.data,
      });
      // dispatch(getCurrentUser());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS_PASSWORD,
        payload: err.response.data,
      })
    );
};

// Login - get user token
export const loginUser = (userData, history) => (dispatch) => {
  axios
    .post("/user/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("token", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      // Set current user
      dispatch(getCurrentUser());
      history.push("/moncompte");
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: {},
      // });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS_CNX,
        payload: err.response.data,
      });
    });
};

// get logged in user
export const getCurrentUser = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/user")
    .then((res) => {
      dispatch(setCurrentUser(res.data));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//demande conge
export const demandeConge = (data) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .post("/conge/add", data)
    .then((res) => {
      dispatch({
        type: GET_SUCCESS_CONGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS_CONGE,
        payload: err.response.data,
      });
    });
};

//demande autorisation
export const demandeAuto = (data) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .post("/auto/add", data)
    .then((res) => {
      dispatch({
        type: GET_SUCCESS_DEMANDE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS_DEMANDE,
        payload: err.response.data,
      });
    });
};

//get autorisation of the AuthUser
export const getAutoAuthUser = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/auto/mine")
    .then((res) => {
      dispatch({
        type: GET_AUTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
//get autorisation

export const getAuto = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/auto")
    .then((res) => {
      dispatch({
        type: GET_AUTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//delete autorisation
export const autoDelete = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .delete(`/auto/delete/${id}`)
    .then((res) => {
      // dispatch(getAuto());
      dispatch({
        type: GET_AUTO,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//get conge

export const getConge = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/conge")
    .then((res) => {
      dispatch({
        type: GET_CONGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS_CONGE,
        payload: err.response.data,
      });
    });
};

export const getCongeAuthUser = () => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .get("/conge/mine")
    .then((res) => {
      dispatch({
        type: GET_CONGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS_CONGE,
        payload: err.response.data,
      });
    });
};

//delete conge
export const congeDelete = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .delete(`/conge/delete/${id}`)
    .then((res) => {
      dispatch({
        type: GET_CONGE,
        payload: res.data,
      });
      // dispatch(getConge());
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: { delete: "ok" },
      // });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS_CONGE,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  // setAuthToken(localStorage.token);
  // axios.put("/user/logout", { online: false }).then((res) => {
  // Remove token from local storage
  localStorage.removeItem("token");

  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  // history.push("/");
  // });
};
