import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { demandeConge } from "../actions/authaction";
import { GET_ERRORS_CONGE, GET_SUCCESS_CONGE } from "../actions/types";
import { useTranslation } from "react-i18next";
import "../style/conge.css";
// import { success } from "concurrently/src/defaults";

function Conge({ returnSection }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors.congeAdded);
  const successes = useSelector((state) => state.success.conge_success);
  const [btnForm, setBtnForm] = useState(false);
  // const [contactErreur, setContactErreur] = useState(true);
  const [congeData, setCongeData] = useState({
    start: "",
    end: "",
    raison: "",
    justif: "",
    type: "notPaid",
  });
  const [errData, setErrData] = useState({
    start: false,
    end: false,
    raison: false,
  });

  const onChange = (e) => {
    if (e.target.id === "justif")
      setCongeData({ ...congeData, [e.target.id]: e.target.files[0] });
    else setCongeData({ ...congeData, [e.target.id]: e.target.value });
    setErrData({ ...errData, [e.target.id]: false });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if (
      congeData.start === "" ||
      congeData.end === "" ||
      congeData.raison === ""
    ) {
      setErrData({
        ...errData,
        start: !congeData.start,
        end: !congeData.end,
        raison: !congeData.raison,
      });
    } else {
      setBtnForm(true);
      let formData = new FormData();
      formData.append(
        "info",
        JSON.stringify({ ...congeData, userId: auth.user._id })
      );
      formData.append("justif", congeData.justif);
      dispatch(demandeConge(formData));
    }
  };
  useEffect(() => {
    if (successes) {
      setTimeout(() => {
        dispatch({
          type: GET_SUCCESS_CONGE,
          payload: null,
        });
        setCongeData({
          start: "",
          end: "",
          raison: "",
          justif: "",
          type: "",
        });
        returnSection();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes]);

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        setBtnForm(false);
        dispatch({
          type: GET_ERRORS_CONGE,
          payload: "",
        });
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    dispatch({
      type: GET_ERRORS_CONGE,
      payload: "",
    });
    dispatch({
      type: GET_SUCCESS_CONGE,
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-conge">
      <div>
        <h3 style={{ textAlign: "center" }}>{t("Demande de congé.1")}</h3>
        <form className="p-4" onSubmit={onSubmit}>
          {auth.user.fname && (
            <div className="form-outline mb-4">
              <label htmlFor="first_name" className="green-text">
                {t("Prénom.1")}
              </label>
              <input
                disabled
                value={auth.user.fname}
                id="first_name"
                type="text"
                className="form-control"
              />
            </div>
          )}
          {auth.user.lname && (
            <div className="form-outline mb-4">
              <label htmlFor="last_name" className="green-text">
                {t("Nom.1")}
              </label>
              <input
                disabled
                value={auth.user.lname}
                id="last_name"
                type="text"
                className="form-control"
              />
            </div>
          )}
          {auth.user.email && (
            <div className="form-outline mb-4">
              <label htmlFor="email" className="green-text">
                E-mail
              </label>
              <input
                disabled
                value={auth.user.email}
                id="email"
                type="text"
                className="form-control"
              />
            </div>
          )}
          {auth.user.fonction && (
            <div className="form-outline mb-4">
              <label htmlFor="fonction" className="green-text">
                {t("Fonction.1")}
              </label>
              <input
                disabled
                value={auth.user.fonction}
                id="fonction"
                type="text"
                className="form-control"
              />
            </div>
          )}
          <label>{t("Raison.1")}*</label>
          <textarea
            type="text"
            id="raison"
            rows={4}
            className="form-control mb-4"
            value={congeData?.raison}
            onChange={onChange}
            required
            style={{ border: errData.raison && "1px solid red" }}
          />
          <label>{t("Type.1")}</label>
          <select
            id="type"
            onChange={onChange}
            value={congeData?.type}
            className="form-control mb-4"
          >
            <option value="notPaid">{t("Type.1")}</option>
            <option value="paid">{t("Type.2")}</option>
            <option value="notPaid">{t("Type.3")}</option>
          </select>

          <label>{t("Date du début.1")}</label>
          <input
            type="date"
            id="start"
            className="form-control mb-4"
            value={congeData?.start}
            onChange={onChange}
            min={new Date().toISOString().split("T")[0]}
            style={{ border: errData.start && "1px solid red" }}
          />
          <label>{t("Date de fin.1")}</label>
          <input
            min={congeData?.start}
            type="date"
            id="end"
            className="form-control mb-4"
            value={congeData?.end}
            onChange={onChange}
            disabled={!congeData.start}
            style={{ border: errData.end && "1px solid red" }}
          />
          <label htmlFor="justif">
            {t("Justificative.1")} ({t("optionnel.1")})
          </label>
          <input
            id="justif"
            name="justif"
            className="form-control mb-4"
            type="file"
            accept="image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
            style={{
              fontSize: 15,
              padding: "0.2rem 0.5rem",
            }}
            onChange={onChange}
          />
          <button
            disabled={btnForm}
            className="btn btn-block"
            type="submit"
            style={{ background: "#464646", color: "white" }}
          >
            {t("Envoyer.1")}
          </button>
        </form>
      </div>

      {successes ? (
        <div
          className="conge-alert"
          style={{
            opacity: successes ? 100 : 0,
            visibility: successes ? "visible" : "hidden",
          }}
        >
          {t("Votre demande a été envoyé avec succès.1")}
        </div>
      ) : null}

      {errors ? (
        <div
          className="conge-alert"
          style={{
            opacity: errors ? 100 : 0,
            visibility: errors ? "visible" : "hidden",
            background: "red",
            padding: "1%",
          }}
        >
          {errors}
        </div>
      ) : null}
    </div>
  );
}

export default Conge;
