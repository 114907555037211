import axios from "axios";
import setAuthToken from "../token/authtoken";
import { GET_ERRORS_START_TEST, CANDIDATE_TEST } from "./types";

export const getCandidateTest = (token) => (dispatch) => {
    setAuthToken(token);
    axios
      .get(`/testQcm/candidateTest`)
      .then((res) => {
        dispatch({
          type: CANDIDATE_TEST,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS_START_TEST,
          payload: err.response.data,
        })
      );
  };