import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Grid from "@mui/material/Grid";
import SwipeableViews from "react-swipeable-views";
import LoadingSpinner from "../../component/LoadingSpinner";
import { getCandidateTest } from "../../actions/testAction";
import Recommandations from "./Recommandations";

const styleTestCan = (theme) => ({
  rootIndex: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
  rootQst2: {
    maxWidth: 1000,
    margin: "0 auto",
  },
});

const CandidateTest = ({ match, history, classes }) => {
  const qcmtest = useSelector((state) => state.qcmTest.candidateTest);
  const error = useSelector((state) => state.errors.startTest);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [testData, setTestData] = useState();

  const [activeStep, setActiveStep] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStart = () => {
    history.push("/quiz/" + match.params.token);
  };

  useEffect(() => {
    let token = match.params.token;
    dispatch(getCandidateTest(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (qcmtest) {
      setTestData(qcmtest);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qcmtest]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {}, [loader]);
  useEffect(() => {
    if (error) setErrorMsg(error);
  }, [error]);
  return (
    <>
      {loader ? <LoadingSpinner /> : null}
      <Paper elevation={1} className={classes.rootIndex}>
        {testData && testData.nbQuestions > 0 ? (
          <div>
            <div className="offre-welcome">
              <h2>{testData?.title}</h2>
              <h4>{testData?.nbQuestions} Questions</h4>
              <h4>
                {" "}
                Durée :{Math.floor(testData.duration / 3600)}h
                {Math.floor((testData.duration % 3600) / 60)}m
                {Math.floor((testData.duration % 3600) % 60)}s
              </h4>
            </div>
            <div style={{ textAlign: "center", padding: "2%" }}>
              <h4>Quelques choses avant de commencer...</h4>
            </div>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.rootQst2}
            >
              <div>
                <SwipeableViews index={activeStep}>
                  <Recommandations
                    message="Chaque question est limitée dans le temps et ne peut être répondue
            qu'une seule fois."
                    imgSrc="/sources/chronometre.webp"
                  />
                  <Recommandations
                    message="Certaines questions peuvent avoir plus d'une bonne réponse."
                    imgSrc="/sources/check.webp"
                  />
                  <Recommandations
                    message="Vous n'êtes pas obligé de répondre à toutes les questions. Vous pouvez sauter à tout moment."
                    imgSrc="/sources/skip.webp"
                  />
                </SwipeableViews>

                <MobileStepper
                  id="MobileStepperQst"
                  steps={3}
                  position="static"
                  variant="dots"
                  activeStep={activeStep}
                  nextButton={
                    <>
                      {activeStep === 2 ? (
                        <Button size="small" onClick={handleStart}>
                          Commencer
                          <KeyboardArrowRight />
                        </Button>
                      ) : (
                        <Button size="small" onClick={handleNext}>
                          Suivant
                          <KeyboardArrowRight />
                        </Button>
                      )}
                    </>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      <KeyboardArrowLeft />
                      Précédent
                    </Button>
                  }
                />
              </div>
            </Grid>
          </div>
        ) : errorMsg !== "" ? (
          <Grid container>
            <Grid container direction="row">
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <img
                  src="/sources/error.webp"
                  loading="lazy"
                  alt="projet"
                  style={{ width: "50%" }}
                />
                <h4>{errorMsg}</h4>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Paper>
    </>
  );
};

export default withStyles(styleTestCan)(CandidateTest);
