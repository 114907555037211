import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import { fetchQuizById, sendTest } from "../../../actions/quizActions";
import LoadingSpinner from "./../../../component/LoadingSpinner";
import "../../../style/quiz.css";
import ActiveQuiz from "./ActiveQuiz";
import FinishedQuiz from "./FinishedQuiz";
import Grid from "@mui/material/Grid";

const styleQuiz = (theme) => ({
  rootIndex: {
    border: `8px solid ${theme.palette.common.white}`,
    margin: 16,
    padding: "36px 0 0",
    background: `rgba(255,255,255,0.9)`,
    boxShadow: [
      `0px 16px 26px -10px ${theme.palette.primary.main}99`,
      theme.shadows[15],
    ],
  },
});

const Quiz = ({ history, match, props, classes }) => {
  // const questions = useSelector((state) => state.qcmTest.testQuestions);
  const quizData = useSelector((state) => state.currentQuiz);
  const error = useSelector((state) => state.errors.errors);
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(true);
  const errorData = useSelector((state) => state.quiz);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchQuizById(match.params.token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (quizData) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [quizData]);

  useEffect(() => {
    if (error) setErrorMsg(error);
  }, [error]);

  useEffect(() => {
    if (quizData.isQuizFinished) {
      dispatch(sendTest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizData]);

  useEffect(() => {
    if (errorData && errorData.error) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorData]);

  useEffect(() => {}, [loader]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  return (
    <>
      {loader ? <LoadingSpinner /> : null}
      <Paper elevation={1} className={classes.rootIndex}>
        <div className="quiz">
          {quizData.quiz ? (
            <>
              {quizData.isQuizFinished ? (
                <FinishedQuiz />
              ) : (
                <ActiveQuiz
                  questionNumber={quizData.activeQuestionNumber + 1}
                  question={quizData.currentQuizQuestion.question}
                  propositions={quizData.currentQuizQuestion.propositions}
                  hasFreeField={quizData.currentQuizQuestion.hasFreeField}
                  duration={quizData.currentQuizQuestion.duration}
                  // onAnswerClick={onAnswerClickHandler}
                  // answerState={quizData.answerState}
                  quizLength={quizData.quiz.length}
                />
              )}
            </>
          ) : errorMsg !== "" ? (
            <Grid container>
              <Grid container direction="row">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <img
                    src="/sources/error.webp"
                    loading="lazy"
                    alt="projet"
                    style={{ width: "50%" }}
                  />
                  <h4>{errorMsg}</h4>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </div>
      </Paper>
    </>
  );
};

export default withStyles(styleQuiz)(Quiz);
