import React from "react";
import classes from "../../../style/AnswersList.css";
import AnswerItem from "./AnswerItem";

const AnswersList = (props) => (
  <ul className={classes.AnswersList}>
    {props.propositions.map((proposition, index) => {
      return (
        <AnswerItem
          key={"propo"+index}
          index={index}
          proposition={proposition}
          quizAnswerClick={props.quizAnswerClick}
          // answerState="false"
        />
      );
    })}
  </ul>
);

export default AnswersList;
