import React, { useEffect, useState } from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../actions/authaction";
import { useHistory } from "react-router-dom";
import "../style/dash.css";
import Tableau from "../component/Tableau";
import ChangePW from "../pages/ChangePW";
import { useTranslation } from "react-i18next";
import Account from "../component/Account";

function Dashboard() {
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selection, setSelection] = useState("bord");
  useEffect(() => {
    if (auth.user.blocage) {
      dispatch(logoutUser());
      history.push("/")
    }
  });
  // useEffect(() => {
  //   if (!localStorage.token) history.push("/connexion");
  // });
  const onLogoutClick = () => {
    dispatch(logoutUser());
    history.push("/")
  };

  return (
    <div>
      <div className="dash-bar">
        <h2>{t("Mon espace.1")}</h2>
        <MDBDropdown>
          <MDBDropdownToggle tag="div">
            <img
              src={auth.user.avatar}
              className="user-avatar"
              alt="user"
              style={{ width: 80, height: 80 }}
            />
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                height: "100%",
                padding: 10,
              }}
              className="drop-user"
            >
              <img
                style={{ borderRadius: "50%", marginBottom: 5 }}
                src={auth.user.avatar}
                height="55px"
                width="55px"
                alt="avatar"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: 600, margin: "2px 0px" }}>
                  <b>{auth.user.fname + " " + auth.user.lname}</b>
                </span>
                <span style={{ color: "black", margin: "2px 0px" }}>
                  {auth.user.email}
                </span>
              </div>
              <button
                className="account"
                onClick={onLogoutClick}
                style={{ marginBottom: "5px" }}
              >
                <i
                  className="fas fa-sign-out-alt"
                  style={{ marginRight: 5 }}
                ></i>
                {t("Déconnexion.1")}
              </button>
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>
      </div>
      <div className="dash">
        <div
          className="slide-dash"
          onMouseLeave={() => {
            document
              .querySelector(".slide-dash")
              .classList.remove("active-hover");
            document
              .querySelector(".slide-dash-hover")
              .classList.remove("active-hover");
          }}
        >
          <div>
            <img src="/sources/ESP6.webp" loading="lazy" alt="dash" />
            <p>{t("Catégories.1")}</p>
          </div>
          <div
            onClick={() => setSelection("bord")}
            style={{
              color: selection === "bord" && "#0380b1",
            }}
          >
            <img src="/sources/ESP7.webp" loading="lazy" alt="dash" />
            <p>{t("Tableau de bord.1")}</p>
          </div>
          <div
            onClick={() => setSelection("information")}
            style={{
              color: selection === "information" && "#0380b1",
            }}
          >
            <img src="/sources/ESP5.webp" loading="lazy" alt="dash" />
            <p>{t("Mes informations.1")}</p>
          </div>

          <div
          onClick={() => setSelection("changePassword")}
            // onClick={() => history.push("/changePassword")}
            style={{
              color: selection === "changePW" && "#0380b1",
            }}
          >
            <img src="/sources/offre-edit.webp" loading="lazy" alt="dash" />
            <p>{t("ChangePW.1")}</p>
          </div>
        </div>
        <div
          className="slide-dash-hover"
          onMouseEnter={() => {
            document.querySelector(".slide-dash").classList.add("active-hover");
            document
              .querySelector(".slide-dash-hover")
              .classList.add("active-hover");
          }}
        >
          <div>
            <img src="/sources/ESP6.webp" loading="lazy" alt="dash" />
          </div>
          <div>
            <img src="/sources/ESP7.webp" loading="lazy" alt="dash" />
          </div>
          <div>
            <img src="/sources/ESP5.webp" loading="lazy" alt="dash" />
          </div>
          <div>
            <img src="/sources/offre-edit.webp" loading="lazy" alt="dash" />
          </div>
        </div>
        <div>
          {selection === "bord" && <Tableau />}
          {selection === "information" && <Account setSelection={setSelection} />}
          {selection === "changePassword" && <ChangePW />}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
