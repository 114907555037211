import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/projet.css";
import { INFORMATION } from "../actions/types";
function Information() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.information &&
        dispatch({
          type: INFORMATION,
        });
    };
    !load.information && (img.src = "/sources/information-msg.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.information ? (
        <div>
          <div className="projet-welcome">
            <h2>{t("Système D'information.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/information">{t("Système d'information.1")}</Link>
            </div> */}
          </div>
          <div className="projet-msg">
            <div>
              <h2 style={{ color: "#f46485" }}>
                {t("Système D'information.1")}
              </h2>
              <p>
                {t(
                  "Vous souhaitez avoir un système d’information performant, évolutif et optimisé ou avoir un SI adapté à votre organisation et efficace.1"
                )}
                .
                <br />
                <b>VIBTIC </b>
                {t(
                  "vous apportera une meilleure connaissance de votre SI grâce aux conseils, analyse et préconisations détaillées.1"
                )}
                .
              </p>
            </div>
            <div>
              <img src="/sources/information-msg.webp" loading="lazy" alt="information" />
            </div>
          </div>
          <div className="projet-para">
            <div>
              <p>
                {t(
                  "Le SI aujourd’hui joue un rôle important au sein d’une entreprise, il est même indispensable à leur bon fonctionnement.1"
                )}
                .
                <br />
                {t(
                  "Un SI performant permet à une entreprise d’optimiser leur processus, de sous-traiter des tâches à faible valeur ajoutée, d’améliorer la relation client, de mieux communiquer et améliorer la productivité.1"
                )}
                .
                <br />
                {t(
                  "Lorsque vous ne savez pas de quoi est composé votre SI, que vous pensez qu’il n’est pas optimisé, ou que vous souhaitez le faire évoluer, réaliser un audit de votre SI peut s’avérer nécessaire.1"
                )}
                .
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Information;
