import { withStyles, Grid } from "@material-ui/core";
const styleRec = () => {
    return {
        rootRec: {
        height: "100%",
        maxWidth: 800,
        margin: "0 auto",
        "& input:disabled": { opacity: 0.5 },
      },     
    };
  };
  
const Recommandations = ({ message, imgSrc, classes }) => {
  return (
    <Grid container className={classes.rootRec}>
      <Grid container direction="row">
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img
            src={imgSrc}
            alt="projet"
            style={{ width: "25%" }}
          />
          <h4>{message}</h4>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(styleRec)(Recommandations);
