import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextareaAutosize } from "@mui/base";
import Button from "@mui/material/Button";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import "../../../style/ActiveQuiz.css";
import AnswersList from "./AnswersList";
import Decrypt from "./Decrypt";

import CountdownTimer from "../../../component/Timer";
import LoadingSpinner from "../../../component/LoadingSpinner";
import {
  onNextClick,
  quizAnswerClick,
  quizFreeFieldAnswer,
} from "../../../actions/quizActions";

const ActiveQuiz = (props) => {
  const dispatch = useDispatch();
  const [dateTimeAfterDuration, setDateTimeAfterDuration] = useState();
  const [freeAnswer, setFreeAnswer] = useState("");

  const onHandleNext = (e) => {
    setFreeAnswer("");
    dispatch(onNextClick());
  };

  const handleChangeAnswer = (e) => {
    e.preventDefault();
    setFreeAnswer(e.target.value);
    dispatch(quizFreeFieldAnswer(e.target.value));
  };
  useEffect(() => {
    if (props) {
      let aDuration = new Date(props.duration);
      let DURATION_IN_MS =
        parseInt(aDuration.getMinutes() * 60 * 1000) +
        parseInt(aDuration.getSeconds() * 1000);
      const NOW_IN_MS = new Date().getTime();
      let dateTime = NOW_IN_MS + DURATION_IN_MS;
      setDateTimeAfterDuration(dateTime);
    }
  }, [props]);

  useEffect(() => {
  }, [dateTimeAfterDuration]);

  if (props && dateTimeAfterDuration)
    return (
      <div style={{ width: "75%" }}>
        <CountdownTimer
          targetDate={dateTimeAfterDuration}
          onHandleNext={onHandleNext}
        />
        <h1 style={{ padding: 40 }}>
          {props.questionNumber}. {Decrypt(props.question)}
        </h1>

        {props.hasFreeField ? (
          <TextareaAutosize
            aria-label="minimum height"
            minRows={10}
            name="freeField"
            value={freeAnswer}
            onChange={handleChangeAnswer}
            // placeholder=""
            style={{ width: "100%" }}
          />
        ) : (
          <AnswersList
            propositions={props.propositions}
            quizAnswerClick={quizAnswerClick}
            // onAnswerClick={props.onAnswerClick}
            // answerState={props.answerState}
          />
        )}

        <div style={{ textAlign: "center" }}>
          {props.questionNumber} / {props.quizLength}
          <Button size="small" onClick={onHandleNext}>
            Suiv
            <KeyboardArrowRight />
          </Button>
        </div>
      </div>
    );
  else return <LoadingSpinner />;
};

export default ActiveQuiz;
