import * as CryptoJS from "crypto-js";

let secretKey = "977d9756ca89efb605ddabd5957b04dab8ce2d97";
const Decrypt = (ciphertext) => {
  const decrypted = CryptoJS.AES.decrypt(ciphertext, secretKey);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        return "error 1";
      }
    } catch (e) {
      return "error 2";
    }
  }
  return "error 3";
};
export default Decrypt;
