import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../style/vibtic.css";
import { VIBTIC } from "../actions/types";

function Vibtic() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.vibtic &&
        dispatch({
          type: VIBTIC,
        });
    };
    !load.vibtic && (img.src = "/sources/vibtic-def.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.vibtic ? (
        <div>
          <div className="vibtic-welcome">
            <h2>{t("Qui Somme Nous.1")} ?</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/vibtic">VIBTIC</Link>
            </div> */}
          </div>
          <img src="/sources/vibtic-def.webp" alt="vibtic" />
          <div className="vibtic-para">
            <h2>{t("Histoire et création.1")}</h2>
            <div>
              {t("La société VIBTIC est une société de conseil et de services spécialisée dans le domaine de l’ingénierie informatique et technologies de l’information.1")}.
              <br />
              {/* {t("La société a été fondée sur une idée simple  réunir les meilleurs experts de l'environnement cible pour assurer la qualité des recommandations et la satisfaction des clients.1")}.
              <br /> */}
              {t("L'objectif est de fournir des services de système d'information de haute qualité dans les domaines des réseaux, datacenter  et de la sécurité.1")}.
             <br />
             {t("Ses avantages sont.1")} :
             <br />
              <span>• {t("L’expertise technique et organisationnelle.1")}</span>
              <br />
              <span>• {t('La polyvalence des compétences.1')}</span>
              <br />
              <span>• {t("L’écoute et la culture du « Service Client ».1")}</span>
              <br />
              {t("Notre image de société dynamique et performante est basée sur la réactivité et la flexibilité pour répondre aux besoins de nos clients.1")}.
            </div>
          </div>
          <div>
            {" "}
            <img src="/sources/ellipse4.webp" alt="ellipse" />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Vibtic;
