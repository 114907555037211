import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getOffre } from "../actions/offreaction";
import "../style/offrePage.css";

function Offre_Page({ match }) {
  const { t } = useTranslation();
  const offres = useSelector((state) => state.offres.offres);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOffre());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const offre = offres && offres.find((el) => el._id === match.params.offreId);

  return (
    <div>
      <div
        className="offrePage-welcome"
      >
          <h1>{t("Rejoignez-nous.1")}</h1>
          <p>
            {t(
              "Nous apportons une approche personnelle et efficace pour chaque projet, nous travaillons sur la qualité de vos projets.1"
            )}
          </p>
      </div>
      {offre && new Date(offre.dateExpiration) <= new Date() && (
        <div className="expiration">
          <p>
            <i className="fas fa-exclamation-circle"></i>
            {t("L'offre a expiré.1")}.
          </p>
        </div>
      )}
      <div className="offre-title">
        <h3>{offre && offre.titre}</h3>
      </div>
      <div className="offre-detail row">
        {offre && offre.nbPostes && (
          <div className="col-lg-4 col-12">
            <h4>{t("Postes vacants.1")} :</h4>
            <p>{offre && offre.nbPostes}</p>
          </div>
        )}
        {offre && offre.typeEmploi && (
          <div className="col-lg-4 col-12">
            <h4>{t("Type d'emploi désiré.1")} :</h4>
            <p>{offre && offre.typeEmploi}</p>
          </div>
        )}
        {offre && offre.experience && (
          <div className="col-lg-4 col-12">
            <h4>Experience :</h4>
            <p>{offre && offre.experience}</p>
          </div>
        )}
        {offre && offre.niveauEtude && (
          <div className="col-lg-4 col-12">
            <h4>{t("Niveau d'étude.1")} :</h4>
            <p>{offre && offre.niveauEtude}</p>
          </div>
        )}
        {offre && offre.rémunérationProposée && (
          <div className="col-lg-4 col-12">
            <h4>{t("Rémunération proposée.1")} :</h4>
            <p>{offre && offre.rémunérationProposée}</p>
          </div>
        )}
        {offre && offre.langue && (
          <div className="col-lg-4 col-12">
            <h4>{t("Langue.1")} :</h4>
            <p>{offre && offre.langue}</p>
          </div>
        )}
        {offre && offre.catégorie && (
          <div className="col-lg-4 col-12">
            <h4>{t("Catégorie.1")} :</h4>
            <p>{offre && offre.catégorie}</p>
          </div>
        )}
        {offre &&
          (offre.pays ||
            offre.emplacement ||
            offre.gouvernorat ||
            offre.codePostal) && (
            <div className="col-lg-4 col-12">
              <h4>{t("Adresse.1")} :</h4>
              <p>
                {offre &&
                  offre.pays +
                    (offre.pays && ", ") +
                    offre.emplacement +
                    (offre.emplacement && ", ") +
                    offre.gouvernorat +
                    (offre.gouvernorat && ", ") +
                    offre.codePostal}
              </p>
            </div>
          )}
        {offre && offre.created_at && (
          <div className="col-lg-4 col-12">
            <h4>{t("Date de mise en ligne.1")} :</h4>
            <p>{offre && offre.created_at.split("T")[0]}</p>
          </div>
        )}
      </div>
      <div className="offre-desc">
        <h2>Description</h2>
        <p>{offre && offre.description}</p>
      </div>
      <div className="offre-desc">
        <h2>Missions</h2>
        <p>{offre && offre.missions}</p>
      </div>
      <div className="offre-desc" style={{ paddingBottom: 20 }}>
        <h2>{t("Exigences de l'emploi.1")}</h2>
        <p>{offre && offre.exigence}</p>
      </div>
      <div className="offre-desc" style={{ paddingBottom: 20 }}>
        <h3>{t("Pour postuler.1")}</h3>
        <p>{offre && offre.email}</p>
      </div>
      <div className="offre-desc" style={{ paddingBottom: 20 }}>
        <h3>{t("Date d'expiration.1")}</h3>
        <p>{offre && offre.dateExpiration}</p>
      </div>
    </div>
  );
}

export default Offre_Page;
