import { combineReducers } from "redux";
import authReducer from "./auth";
import load from "./loadAction";
import offreReducer from "./offre";
import errorReducer from "./error";
import congeReducer from "./conge";
import autoReducer from "./auto";
import qcmReducer from "./qcm";
import quizReducer from "./quiz";
import taskReducer from "./task";
import successReducer from "./success";

export default combineReducers({
  auth: authReducer,
  load: load,
  offres: offreReducer,
  errors: errorReducer,
  conge: congeReducer,
  auto: autoReducer,
  qcmTest: qcmReducer,
  currentQuiz: quizReducer,
  task: taskReducer,
  success: successReducer,
});
