import axios from "axios";
import setAuthToken from "../token/authtoken";
import { GET_ERRORS, GET_TASK, GET_TASKS } from "./types";


export const newTask = (data) => (dispatch) => {
    setAuthToken(localStorage.token);
    axios
      .post("/task/add", data)
      .then((res) => {
        dispatch({
          type: GET_TASK,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

  export const getTasksAuthUser = () => (dispatch) => {
    setAuthToken(localStorage.token);
    axios
      .get("/task/mine")
      .then((res) => {
        dispatch({
          type: GET_TASKS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

  export const getTaskByID = (id) => (dispatch) => {
    axios
      .get(`/task/${id}`)
      .then((res) => {
        dispatch({
          type: GET_TASK,
          payload: res.data,
        });
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };

export const taskDelete = (id) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .delete(`/task/delete/${id}`)
    .then((res) => {
      dispatch({
        type: GET_TASKS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateTask = (taskData) => (dispatch) => {
  setAuthToken(localStorage.token);
  axios
    .put("/task/update", taskData)
    .then((res) => {
      dispatch({
        type: GET_TASK,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};