import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/home.css";
import { HOME } from "../actions/types";
function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  useEffect(() => {
    const img = new Image();
    const img1 = new Image();
    img1.onload = () => {
      img.onload = () => {
        !load.home &&
          dispatch({
            type: HOME,
          });
      };
      !load.home && (img.src = "/sources/service1.webp");
    };
    !load.home && (img1.src = "/sources/home_welcome.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.home ? (
        <div>
          <div className="welcome-home row">
            <div className="col-lg-6 col-12">
              <h2>
                {" "}
                <span>
                  {t("Société Spécialisée.1")} <br /> {t("En.1")}
                </span>{" "}
                <br /> Services <br /> {t("d'Ingénierie Informatique.1")}
              </h2>
              <div>
                <Link to="/vibtic">{t("Qui Somme Nous.1")} ?</Link>{" "}
                <Link to="/contact">Contact</Link>{" "}
              </div>
              <img src="/sources/ellipse-rose.webp" loading="lazy" alt="ellipse" />
              <img src="/sources/ellipse-bleu.webp" loading="lazy" alt="ellipse2" />
              <img src="/sources/triangle-bleu.webp" loading="lazy" alt="triangle" />
              <img src="/sources/triangle2.webp" loading="lazy" alt="triangle2" />
            </div>
            <div className="col-lg-6 col-12">
              <img src="/sources/home_welcome.webp" loading="lazy" alt="accueil" />
              <img src="/sources/ellipse3.webp" loading="lazy" alt="ellipse3" />
            </div>
            <img src="/sources/forme1.webp" loading="lazy" alt="forme" />
          </div>
          <div className="competence row">
            <div className="col-lg-6 col-12">
              <h2>{t("Nos Compétences.1")}</h2>
              <p>
                {t(
                  "L’équipe VIBTIC se compose d’analystes, d’experts de développeurs d’applications et d’infrastructure réseau et sécurité, hautement qualifiés.1"
                )}.
              </p>
              <Link to="/competence">{t("En Savoir Plus.1")}</Link>
            </div>

            <div className="col-lg-6 col-12">
              <img src="/sources/competence.webp" loading="lazy" alt="competence" />
            </div>
          </div>
          <div className="services row">
            <div className="col-lg-6 col-12">
              <h2>Services</h2>
              <p>
                {t("Nous fournissons des solutions créatives.1")} !
                <br />
                {t(
                  "Nous développons vos idées en des applications puissantes et robustes.1"
                )}.
              </p>
              <img src="/sources/service1.webp" loading="lazy" alt="service" />
              <img src="/sources/cercle.webp" loading="lazy" alt="cercle" />
            </div>
            <div className="col-lg-6 col-12">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div onClick={() => history.push("/projet")}>
                    <img src="/sources/projet.webp" loading="lazy" alt="projet" />
                    <h4>{t("Gestion et management de Projet.1")}</h4>
                    <p>
                      {t(
                        "Une exécution réussie avec un conseil efficace en gestion du projet.1"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div onClick={() => history.push("/information")}>
                    <img src="/sources/systeme.webp" loading="lazy" alt="systeme" />
                    <h4>{t("Système D'information.1")}</h4>
                    <p>
                      {t(
                        "Le système d’information est un élément central d’une entreprise ou d’une organisation.1"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div onClick={() => history.push("/reseau")}>
                    <img src="/sources/reseau.webp" loading="lazy" alt="reseau" />
                    <h4>{t("Réseaux et Sécurité.1")}</h4>
                    <p>
                      {t(
                        "Performance, sécurité et flexibilité sont les critères requis par des métiers toujours plus connectés et nomades.1"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div onClick={() => history.push("/web")}>
                    <img src="/sources/web.webp" loading="lazy" alt="web" />
                    <h4>{t("Développement Informatique.1")}</h4>
                    <p>
                      {t(
                        "Nous concevons et développons des applications Web, des sites et des portails réactifs, hautement performants et évolutifs.1"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div onClick={() => history.push("/cloud")}>
                    <img src="/sources/cloud.webp" loading="lazy" alt="cloud" />
                    <h4>{t("Solution CLOUD.1")}</h4>
                    <p>
                      {t(
                        "VIBTIC est présent tout au long du cycle de vie du Cloud pour intégrer des solutions numériques sécurisées dans l’entreprise.1"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div onClick={() => history.push("/data")}>
                    <img src="/sources/data.webp" loading="lazy" alt="data" />
                    <h4>{t("Data Center.1")}</h4>
                    <p>
                      {t(
                        "Le Data Center devient aujourd’hui le point névralgique du système d’information et de communication des entreprises.1"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <img src="/sources/ellipse4.webp" loading="lazy" alt="ellipse" />
          </div>
          <div className="rejoindre">
            <div>
              <img src="/sources/rejoindre.webp" loading="lazy" alt="rejoindre" />
            </div>
            <div>
              <h2>{t("Rejoignez-nous.1")}</h2>
              <p>
                {t(
                  "Nous apportons une approche personnelle et efficace pour chaque projet, nous travaillons sur la qualité de vos projets.1"
                )}.
              </p>
              <Link to="/offres">{t("Nos Offres.1")}</Link>
            </div>
          </div>
          <div className="valeur">
            <div>
              <h2>{t("Nos Valeurs.1")}</h2>
              <p>
                {t(
                  "L’EXPERTISE, le DYNAMISME, la TRANSPARENCE et la PROXIMITÉ, sont nos valeurs communes.1"
                )}
                .
                <br />
                {t(
                  "Elles nous permettent d’être un véritable partenaire de confiance pour nos clients.1"
                )}.
              </p>
              <img src="/sources/07.webp" loading="lazy" alt="anim" />
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-3">
                <div style={{ backgroundImage: "url(/sources/expertise.webp)" }}>
                  <h3>EXPERTISE</h3>
                  <p>
                    {t(
                      "Société technique et de culture d’ingénieur, l’essence de la société VIBTIC est de rassembler les meilleures compétences dans ses domaines d’intervention Aujourd’hui nos équipes d’experts mènent à bien leurs différentes missions de conseil avec un fort niveau d’exigence et de rigueur.1"
                    )}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div style={{ backgroundImage: "url(/sources/dynamisme.webp)" }}>
                  <h3>{t("DYNAMISME.1")}</h3>
                  <p>
                    {t(
                      "Animées d’un esprit entrepreneurial, nos équipes, polyvalentes et réactives, sont engagées dans leurs missions et dans l’entreprise Attentifs aux évolutions du marché, nous cherchons à anticiper le changement, penser de façon créative, être innovants et dans l’apprentissage permanent Notre équipe expérimentée, sympathique et pluridisplinaire est à votre écoute.1"
                    )}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div style={{ backgroundImage: "url(/sources/transparence.webp)" }}>
                  <h3>{t("TRANSPARENCE.1")}</h3>
                  <p>
                    {t(
                      "Nous nous engageons auprès de nos collaborateurs et de nos clients, dans une relation long terme dont la confiance est le ciment Ce qui implique de travailler en transparence, communiquer avec sincérité et d’être solidaires les uns des autres dans les réussites comme dans les difficultés.1"
                    )}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div style={{ backgroundImage: "url(/sources/esprit.webp)" }}>
                  <h3>{t("Esprit d’équipe & Management de proximité.1")}</h3>
                  <p>
                    {t(
                      "La confiance, le respect mutuel, l’écoute, le dialogue la considération et la valorisation des contributions entre tous assurent le maintien d’une équipe forte, capable de relever les défis et de satisfaire nos clients, dans le respect des valeurs de notre Groupe Nous construisons ensemble l’entreprise de demain en assurant une forte réactivité dans ce milieu en constante transformation.1"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="engagement">
          <h2>{t("Nos engagements RSE (responsabilité sociétale d’entreprise).1")}</h2>
              <p>
                <span>VIBTIC</span>{" "}
                {t(
                  "œuvre en faveur du développement durable, axant ses efforts sur l’environnement, les clients, l’humain, la gouvernance et l’éthique pour.1"
                )}
                :
                <br/>
                •	{t("Assurer à nos collaborateurs un univers de travail sain, juste et motivant.1")}.
              <br/>
              •	{t("S’engager pour la planète ; ce qui implique notamment pour nous de prodiguer du conseil & des services qui soient également compatibles avec la réduction de l’impact environnemental des activités de nos clients.1")}.
              <br/>
              •	{t("Garantir des pratiques transparentes et déontologiques auprès de nos clients, partenaires et fournisseurs.1")}.
              </p>
          </div>
          <div className="partenaire">
            <h2>{t("Nos Partenaires.1")}</h2>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.tdf.fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/sources/tdf.webp"
                      loading="lazy"
                      alt="tdf"
                      style={{ height: 90 }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.orange.fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/sources/orange.webp"
                      loading="lazy"
                      alt="orange"
                      style={{ height: 80 }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.checkpoint.com/fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/sources/checkpoint.svg" loading="lazy" alt="checkpoint" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.lefigaro.fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/sources/figaro.svg" loading="lazy" alt="figaro" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.cisco.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/sources/cisco.webp" loading="lazy" alt="cisco" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.sfr.fr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/sources/sfr.webp"
                      loading="lazy"
                      alt="sfr"
                      style={{ height: 80 }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://mabanque.bnpparibas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/sources/bnp.svg" loading="lazy" alt="bnp" />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div>
                  <a
                    href="https://www.safran-group.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/sources/safran.svg" loading="lazy" alt="safran" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Home;
