import React from "react";
import Grid from "@mui/material/Grid";

const FinishedQuiz = () => {
  return (
    <Grid container >
      <Grid container direction="row">
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img
            src="/sources/done.webp"
            loading="lazy"
            alt="projet"
            style={{ width: "50%" }}
          />
          <h4>Votre test est terminé.</h4>
          <p>Merci pour votre temps </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinishedQuiz;
