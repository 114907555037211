import React from "react";
import "../style/spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="Douter outer">
      <div className="Douter inner">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      </div>
    </div>
  );
}
