import { GET_SUCCESS, GET_SUCCESS_PASSWORD, GET_SUCCESS_CONGE, GET_SUCCESS_DEMANDE } from "../actions/types";
const initialState = {
  success: null,
  password_success: null,
  conge_success: null,
  demande_success: null
};
export default function err(state = initialState, action) {
  switch (action.type) {
    case GET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
      case GET_SUCCESS_PASSWORD:
        return {
          ...state,
          password_success: action.payload,
        };
        case GET_SUCCESS_CONGE:
          return {
            ...state,
            conge_success: action.payload,
          };
          case GET_SUCCESS_DEMANDE:
          return {
            ...state,
            demande_success: action.payload,
          };
    default:
      return state;
  }
}