import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { congeDelete, getCongeAuthUser } from "../actions/authaction";
import { useTranslation } from "react-i18next";
import "../style/congeList.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
// import { GET_ERRORS } from "../actions/types";
function CongeList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const conge = useSelector((state) => state.conge.conge);
  const auth = useSelector((state) => state.auth);
  // const error = useSelector((state) => state.errors.errors);
  const [congeId, setCongeId] = useState("");
  const [modal, setModal] = useState(false);
  useEffect(() => {
    dispatch(getCongeAuthUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (error && error.delete) {
  //     toggle();
  //     dispatch({
  //       type: GET_ERRORS,
  //       payload: {},
  //     });
  //   }
  // });
  const toggle = () => {
    setModal(!modal);
  };
  const deleteConge = (id) => {
    toggle();
    dispatch(congeDelete(id));
  };

  useEffect(() => {}, [conge]);
  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", marginBottom: 30 }}>
          {t("Liste de congé.1")}
        </h3>
      </div>
      {conge && conge.length !== 0 ? (
        <>
          {conge
            .slice(0)
            .reverse()
            .filter((el) => el.userId === auth.user._id)
            .map((el) => {
              return (
                <div key={el._id} className="collap-conge">
                  <div>
                    <div>
                      <p>Date :</p>
                      {el.start + " / " + el.end}
                    </div>
                    <div>
                      {!el.etat && new Date(el.start) >= Date.now() ? (
                        <img
                          title={t("supprimer.1")}
                          src="/sources/delete.webp"
                          loading="lazy"
                          alt="corbeille"
                          onClick={() => {
                            toggle();
                            setCongeId(el._id);
                          }}
                        />
                      ) : null}
                    </div>
                    <div style={{ background: el.justif && "#efe6ec" }}>
                      {el.justif && (
                        <a href={el.justif}>
                          <img src="/sources/pdf.webp" loading="lazy" alt="pdf" />
                        </a>
                      )}
                    </div>
                    <div>
                      <div>
                        <p>{t("Validé.1")} :</p>
                        <div style={{ background: el.etat && "#c4eae2" }}>
                          {el.etat && (
                            <i
                              className="fas fa-check"
                              style={{ color: "#40d6b8" }}
                            ></i>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>{t("Non validé.1")} :</p>
                        <div style={{ background: !el.etat && "#efe6ec" }}>
                          {!el.etat && (
                            <i
                              className="fas fa-check"
                              style={{ color: "#a83682" }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      {t("Demande de congé.1")}{" "}
                      {el?.type === "paid"
                        ? t("Type.2")
                        : el?.type === "notPaid"
                        ? t("Type.2")
                        : null}{" "}
                      :
                    </p>
                    {el.raison}
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        conge && (
          <div className="no-offre">
            <p>
              <i className="fas fa-exclamation"></i>
              {t("Liste vide.1")}.
            </p>
          </div>
        )
      )}
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>
          {t("Supprimer la demande de congé.1")}
        </MDBModalHeader>
        <MDBModalBody>
          {t("êtes-vous sûr de vouloir supprimer la demande.1")} ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggle}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            {t("Fermer.1")}
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => deleteConge(congeId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            {t("Supprimer.1")}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
}

export default CongeList;
