import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdbreact";
// import { useDispatch, useSelector } from "react-redux";
// import { logoutUser } from "../actions/authaction";
import "../style/footer.css";
function Footer() {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("fr");
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
  };
  // const auth = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  const date = new Date();

  // const onLogoutClick = () => {
  //   dispatch(logoutUser());
  // };

  return (
    <div className="footer">
      <img src="/sources/form.webp" loading="lazy" alt="shape" />

      <div>
        <img
          src="/sources/vibtic.webp"
          loading="lazy"
          alt="accueil"
          onClick={() => history.push("/")}
        />
        <img src="/sources/triangle3.webp" loading="lazy" alt="triangle" />
      </div>

      <div className="row">
        <div className="col-lg-4 col-12 footer-section">
          <h4>{t("A propos.1")}</h4>
          <div style={{ border: "none" }}>
            <Link to="/">
              <p>{t("Accueil.1")}</p>
            </Link>
            <Link to="/competence">
              <p>{t("Nos Compétences.1")}</p>
            </Link>
            <Link to="/offres">
              <p>{t("Nos offres.1")}</p>
            </Link>

            {!localStorage.token
              ? location.pathname !== "/connexion" && (
                  <Link to="/connexion">
                    <p>Intranet</p>
                  </Link>
                  // <span
                  //   style={{
                  //     fontSize: 15,
                  //     color: "white",
                  //     cursor: "pointer",
                  //     padding: "5px 10px 8px",
                  //     backgroundColor: "#0380b1",
                  //     borderRadius: 25,
                  //   }}
                  //   title={t("Mon espace.1")}
                  //   onClick={() => history.push("/connexion")}
                  // >
                  //   Intranet
                  // </span>
                )
              : null}
          </div>
        </div>
        <div className="col-lg-4 col-12 footer-section">
          <h4>{t("Services.1")}</h4>
          <div>
            <Link to="/information">
              {" "}
              <p>{t("Système d'information.1")}</p>
            </Link>
            <Link to="/reseau">
              {" "}
              <p>{t("Réseaux & sécurité.1")}</p>
            </Link>
            <Link to="/web">
              <p>{t("Développement informatique.1")}</p>
            </Link>
            <Link to="/projet">
              <p>{t("Gestion et mangement de projet.1")}</p>
            </Link>
            <Link to="/cloud">
              <p>{t("Solution CLOUD.1")}</p>
            </Link>
            <Link to="/data">
              <p>{t("Data Center.1")}</p>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-12 footer-social" style={{ padding: 0 }}>
          <h4>{t("Réseaux sociaux.1")}</h4>
          <div style={{ paddingTop: 10 }}>
            <a
              href="https://www.facebook.com/vibticfrance/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={"/sources/facebook1.webp"}
                loading="lazy"
                alt="facebook"
                width="40px"
                height="40px"
              />
            </a>
            <a
              href="https://www.instagram.com/vibtic_france/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={"/sources/instagram1.webp"}
                loading="lazy"
                alt="twitter"
                width="40px"
                height="40px"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/vibtic-france/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={"/sources/linkedin.webp"}
                loading="lazy"
                alt="linkedin"
                width="36px"
                height="36px"
              />
            </a>
          </div>
          <div>
            <img
              src="/sources/francais-v2.webp"
              loading="lazy"
              alt="francais"
              width="40px"
              height="40px"
              title={t("français.1")}
              onClick={() => {
                changeLanguage("fr");
              }}
              style={{ filter: lang === "fr" && "grayscale(0%)" }}
            />
            <img
              src="/sources/anglais-v2.webp"
              loading="lazy"
              alt="anglais"
              width="40px"
              height="40px"
              title={t("anglais.1")}
              onClick={() => {
                changeLanguage("en");
              }}
              style={{ filter: lang === "en" && "grayscale(0%)" }}
            />
          </div>
        </div>
      </div>

      <div>
        <p>
          <span>
            © Copyright <Link to="/">VIBTIC</Link> {date.getFullYear()}{" "}
          </span>
          | 3 AVENUE CHARLES DE GAULLE 94470 BOISSY-SAINT-LEGER, FRANCE
        </p>
      </div>
    </div>
  );
}

export default Footer;
