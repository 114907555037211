export const APP ="APP";
export const HOME = "HOME";
export const SERVICES = "SERVICES";
export const COMPETENCE = "COMPETENCE"
export const CONTACT = "CONTACT"
export const OFFRES = "OFFRES"
export const CANDIDATURE = "CANDIDATURE"
export const CONNEXION = "CONNEXION"
export const VIBTIC = "VIBTIC"
export const PROJET = "PROJET"
export const WEB = "WEB"
export const CLOUD = "CLOUD"
export const DATA = "DATA"
export const INFORMATION = "INFORMATION"
export const RESEAU = "RESEAU"
export const GET_ERRORS = "GET_ERRORS";
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_SUCCESS_PASSWORD = "GET_SUCCESS_PASSWORD";
export const GET_ERRORS_PASSWORD = "GET_ERRORS_PASSWORD";
export const GET_OFFRE = "GET_OFFRE";
export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const GET_CONGE = "GET_CONGE"
export const GET_ERRORS_CONGE = "GET_ERRORS_CONGE"
export const GET_SUCCESS_CONGE = "GET_SUCCESS_CONGE"
export const GET_AUTO = "GET_AUTO"
export const CANDIDATE_TEST = "CANDIDATE_TEST";
export const CANDIDATE_QUESTIONS = "CANDIDATE_QUESTIONS"
export const GET_TASK = "GET_TASK"
export const GET_TASKS = "GET_TASKS"
export const FETCH_START = "FETCH_START";
export const FETCH_FAILED = "FETCH_FAILED";
export const GET_ERRORS_DEMANDE ="GET_ERRORS_DEMANDE";
export const GET_SUCCESS_DEMANDE ="GET_SUCCESS_DEMANDE";
export const GET_ERRORS_START_TEST = "GET_ERRORS_START_TEST";
export const FETCH_QUIZ_LIST_SUCCEED = "FETCH_QUIZ_LIST_SUCCEED";
export const FETCH_QUIZ_SUCCEED = "FETCH_QUIZ_SUCCEED";
export const QUIZ_SET_STATE = "QUIZ_SET_STATE";
export const QUIZ_FINISHED = "QUIZ_FINISHED";
export const QUIZ_NEXT_QUESTION = "QUIZ_NEXT_QUESTION";
export const QUIZ_RETRY = "QUIZ_RETRY";
export const GET_ERRORS_CNX = "GET_ERRORS_CNX"