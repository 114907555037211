import "./App.css";
import Navbar from "./layout/Navbar";
import { Route, Switch } from "react-router-dom";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import { useEffect, useState } from "react";
import Competence from "./pages/Competence";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP } from "./actions/types";
import Contact from "./pages/Contact";
import Offres from "./pages/Offres";
import Candidature from "./pages/Candidature";
// import Unvalid from "./pages/Unvalid";
import Offre_Page from "./pages/Offre_Page";
import Login from "./pages/Login";
import { getOffre } from "./actions/offreaction";
import { getCurrentUser } from "./actions/authaction";
import Dashboard from "./pages/Dashboard";
import Vibtic from "./pages/Vibtic";
import Projet from "./pages/Projet";
import Information from "./pages/Information";
import Reseau from "./pages/Reseau";
import Web from "./pages/Web";
import Cloud from "./pages/Cloud";
import Data from "./pages/Data";
import CandidateTest from "./pages/CandidateTest";
import Questions from "./pages/CandidateTest/Quiz";
import { logoutUser } from "./actions/authaction";
import Activity from "./component/Activity";
import ChangePW from "./pages/ChangePW";
import Recover from "./pages/Recover";
import { GET_ERRORS } from "./actions/types";

function App() {
  const [up, setUp] = useState(false);
  const load = useSelector((state) => state.load);
  const errors = useSelector((state) => state.errors.errors);
  const dispatch = useDispatch();
  const history = useHistory();
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    dispatch(getOffre());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // if (!localStorage.token || localStorage.token === "undefined")
    //   history.push("/");
    // else dispatch(getCurrentUser());
    dispatch({
      type: GET_ERRORS,
      payload: null,
    });
    if (localStorage.token) {
      dispatch(getCurrentUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = localStorage.token;
    if (token) {
      const decodedJwt = parseJwt(token);
      if (!decodedJwt.exp){
        dispatch(logoutUser());
        history.push("/")
      } 
      const expiryDate = new Date(decodedJwt.exp * 1000);
      if (expiryDate < Date.now()) {
        dispatch(logoutUser());
        history.push("/")
      }
    }
    if (errors) {
      dispatch(logoutUser(history));
    }
  });


  useEffect(() => {
    const img = new Image();
    const img1 = new Image();
    const img2 = new Image();
    img2.onload = () => {
      img1.onload = () => {
        img.onload = () => {
          !load.app &&
            dispatch({
              type: APP,
            });
        };
        !load.app && (img.src = "/sources/home_welcome.webp");
      };
      !load.app && (img1.src = "/sources/vibtic.webp");
    };
    !load.app && (img2.src = "/sources/form.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 450) {
        setUp(true);
      } else {
        setUp(false);
      }
    });
  });
  useEffect(() => {
    if (window.scrollY > 450) {
      setUp(true);
    }
  }, []);

  return (
    <>
      {load && load.app ? (
          <div className="App" style={{ paddingTop: 70 }}>
            <div className="App_center">
              <Navbar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/vibtic" component={Vibtic} />
                <Route path="/projet" component={Projet} />
                <Route path="/information" component={Information} />
                <Route path="/reseau" component={Reseau} />
                <Route path="/web" component={Web} />
                <Route path="/cloud" component={Cloud} />
                <Route path="/data" component={Data} />
                <Route path="/competence" component={Competence} />
                <Route path="/contact" component={Contact} />
                <Route exact path="/offres" component={Offres} />
                <Route path="/offres/:offreId" component={Offre_Page} />
                <Route path="/candidature" component={Candidature} />
                <Route path="/connexion" component={Login} />
                <Route path="/moncompte" component={Dashboard} />
                <Route path="/startTest/:token" component={CandidateTest} />
                <Route path="/quiz/:token" component={Questions} />
                <Route path="/task/:id" component={Activity} />
                <Route path="/changePassword" component={ChangePW} />
                <Route path="/recover" component={Recover} />
                <Route path="*" component={Home} />
              </Switch>
            </div>
            <Footer />
            <div
              className="top-arrow"
              style={{
                opacity: up && "1",
                overflow: up && "visible",
                top: up && "92%",
              }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <i className="fas fa-angle-up"></i>
            </div>
          </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}

      
    </>
  );
}

export default App;
