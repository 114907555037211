import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/projet.css";
import { RESEAU } from "../actions/types";
function Reseau() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.reseau &&
        dispatch({
          type: RESEAU,
        });
    };
    !load.reseau && (img.src = "/sources/reseau-msg.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.reseau ? (
        <div>
          <div className="projet-welcome">
            <h2>{t("Réseaux et Sécurité.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/reseau">{t("Réseaux et Sécurité.1")}</Link>
            </div> */}
          </div>
          <div className="projet-msg">
            <div>
              <h2 style={{ color: "#2cb79b " }}>
                {t("Réseaux et Sécurité.1")}
              </h2>
              <p>
                <b>VIBTIC</b>{" "}
                {t(
                  "vous accompagne pour tous vos besoins en réseaux et sécurité informatiques.1"
                )}
                .
              <br/>
                {t(
                  "Nous souhaitons maintenir la sécurité de votre solution tout au long de son cycle de vie  formation, évaluation des risques, monitoring de la sécurité, gestion des vulnérabilités, gestion des incidents et récupération.1"
                )}
                .
              </p>
            </div>
            <div>
              <img src="/sources/reseau-msg.webp" loading="lazy" alt="reseau" />
            </div>
          </div>
          <div className="projet-para">
            <h2>
              {t(
                "Pas de transformation numérique sans confiance, pas de confiance sans cybersécurité.1"
              )}{" "}
              !
            </h2>
            <div>
              <p>
                {t(
                  "Dans un monde en constante mutation, où les données sont devenues l’or noir du XXIeme siècle, la transformation numérique, dont la cybersécurité est une condition impérative de succès, imprègne profondément les processus métiers et les activités business de nos clients.1"
                )}
                .
              <br/>
                {t(
                  "On vous apporte une réponse à toutes les difficultés en sécurisation des réseaux locaux et des infrastructures que vous pouvez rencontrer.1"
                )}
              <br/>
                {t(
                  "Selon vos besoins, nos équipes disposent de compétences, experts en solutions sécurité réseaux assure votre satisfaction.1"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Reseau;
