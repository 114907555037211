import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { MDBCollapse } from "mdbreact";
import { MDBIcon } from "mdbreact";
import { useTranslation } from "react-i18next";
import "../style/navbar.css";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../actions/authaction";
function Navbar() {
  const location = useLocation();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [drop, setDrop] = useState({ valeur: 0 });
  const [size, setSize] = useState(false);
  const [clk, setClk] = useState(false);
  const [collapseID, setCollapseID] = useState("");
  const [colNum, setColNum] = useState(0);
  const [shadow, setShadow] = useState(false);
  const { t } = useTranslation();

  const toggleCollapse = (collapse) => {
    setCollapseID(collapse !== collapseID ? collapse : "");
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setShadow(true);
        document.querySelector(".dash-bar >.dropdown") &&
          (document.querySelector(".dash-bar >.dropdown").style.zIndex = 5);
        document.querySelector(".login-bar>img") &&
          (document.querySelector(".login-bar>img").style.zIndex = 5);
      } else {
        setShadow(false);
        document.querySelector(".dash-bar >.dropdown") &&
          (document.querySelector(".dash-bar >.dropdown").style.zIndex = 11);
        document.querySelector(".login-bar>img") &&
          (document.querySelector(".login-bar>img").style.zIndex = 11);
      }
    });
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 991) {
        setSize(true);
      } else {
        setSize(false);
      }
    });
  });

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setSize(true);
    }
    if (window.scrollY > 0) {
      setShadow(true);
      document.querySelector(".dash-bar >.dropdown") &&
        (document.querySelector(".dash-bar >.dropdown").style.zIndex = 5);
      document.querySelector(".login-bar>img") &&
        (document.querySelector(".login-bar>img").style.zIndex = 5);
    }
  }, []);

  const onLogoutClick = () => {
    dispatch(logoutUser());
    history.push("/")
  };
  return (
    <>
      {size ? (
        <div
          className="navb"
          style={{ background: location.pathname === "/" && "#eef5f7" }}
        >
          <div
            className="row "
            style={{
              height: !clk && 70,
              transition: "background 0.4s",
              margin: 0,
              width: "100%",
              boxShadow: (clk || shadow) && "0px 1px 5px 0px rgba(0,0,0,0.75)",
              background: shadow && "white",
            }}
          >
            <div
              className="col-6 pad"
              style={{
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Link to="/">
                <img
                  src="/sources/vibtic.webp"
                  loading="lazy"
                  alt="vibtic"
                  className="img_vib"
                  style={{
                    width: 120,
                    height: 70,
                    marginTop: 5,
                    marginLeft: "-30%",
                  }}
                />
              </Link>
            </div>
            <div
              className="col-6 icon"
              style={{
                height: 70,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                fontSize: 25,
              }}
            >
              {!localStorage.token ? (
                <span
                  style={{
                    fontSize: 15,
                    color: "white",
                    cursor: "pointer",
                    padding: "5px 10px 8px",
                    backgroundColor: "#0380b1",
                    borderRadius: 25,
                    marginRight: 25,
                  }}
                  title={t("Mon espace.1")}
                  onClick={() => history.push("/connexion")}
                >
                  Connexion
                </span>
              ) : (
                <MDBDropdown>
                  <MDBDropdownToggle tag="div">
                    <img
                      src={auth.user.avatar}
                      className="user-avatar"
                      alt="user"
                      style={{ marginRight: 25, marginBottom: 2 }}
                    />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu basic>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        height: "100%",
                        padding: 10,
                      }}
                      className="drop-user"
                    >
                      <img
                        style={{ borderRadius: "50%", marginBottom: 5 }}
                        src={auth.user.avatar}
                        height="55px"
                        width="55px"
                        alt=""
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: 600, margin: "2px 0px" }}>
                          <b>{auth.user.fname + " " + auth.user.lname}</b>
                        </span>
                        <span style={{ color: "black", margin: "2px 0px" }}>
                          {auth.user.email}
                        </span>
                        
                      </div>
                      <button
                        className="account"
                        onClick={() => history.push("/moncompte")}
                        style={{ marginBottom: "5px" }}
                      >
                        <i
                          className="fas fa-cog"
                          style={{ marginRight: 5 }}
                        ></i>
                        {t("Mon compte.1")}
                      </button>
                      <button
                        className="account"
                        onClick={onLogoutClick}
                        style={{ marginBottom: "5px" }}
                      >
                        <i
                          className="fas fa-sign-out-alt"
                          style={{ marginRight: 5 }}
                        ></i>
                        {t("Déconnexion.1")}
                      </button>
                    </div>
                  </MDBDropdownMenu>
                </MDBDropdown>
              )}
              <MDBIcon
                icon={clk ? "times" : "align-justify"}
                className="black-text"
                onClick={() => setClk(!clk)}
              />
            </div>

            {clk && (
              <div
                className="col-10 pad"
                style={{ marginBottom: 15, lineHeight: "23px" }}
              >
                <ul
                  className="ul_drop"
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                    fontWeight: 500,
                  }}
                >
                  <li>
                    <Link to="/">
                      {" "}
                      <span
                        style={{
                          color: location.pathname === "/" && "#0380b1",
                        }}
                      >
                        {t("Accueil.1")}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <span
                      onClick={() => {
                        toggleCollapse("basicCollapse");
                        setColNum(collapseID && colNum === 1 ? 0 : 1);
                      }}
                      style={{
                        color:
                          (location.pathname === "/projet" ||
                            location.pathname === "/reseau" ||
                            location.pathname === "/information" ||
                            location.pathname === "/web" ||
                            location.pathname === "/cloud" ||
                            location.pathname === "/data") &&
                          "#0380b1",
                      }}
                    >
                      Services
                    </span>
                    
                    <MDBCollapse
                      id="basicCollapse"
                      isOpen={colNum === 1 && collapseID}
                    >
                      <ul
                        style={{
                          listStyleType: "none",
                          paddingLeft: 10,
                          margin: 0,
                        }}
                      >
                        <li>
                          <Link
                            to="/projet"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/projet"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("gestion et management de projet.1")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/information"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/information"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("système d'information.1")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/reseau"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/reseau"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("réseaux & sécurité.1")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/web"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/web"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("développement informatique.1")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/cloud"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/cloud"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("solution cloud.1")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/data"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/data"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("data center.1")}
                          </Link>
                        </li>
                      </ul>
                    </MDBCollapse>
                  </li>
                  <li>
                    <span
                      onClick={() => {
                        toggleCollapse("basicCollapse");
                        setColNum(collapseID && colNum === 2 ? 0 : 2);
                      }}
                      style={{
                        color:
                          (location.pathname === "/candidature" ||
                            location.pathname === "/offres") &&
                          "#0380b1",
                      }}
                    >
                      {t("Carrière.1")}
                    </span>
                    <MDBCollapse
                      id="basicCollapse"
                      isOpen={colNum === 2 && collapseID}
                    >
                      <ul
                        style={{
                          listStyleType: "none",
                          paddingLeft: 10,
                          margin: 0,
                        }}
                      >
                        <li>
                          <Link
                            to="/offres"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/offres"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("nos offres d'emploi.1")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/candidature"
                            style={{
                              fontSize: 16,
                              color:
                                location.pathname === "/candidature"
                                  ? "#0380b1"
                                  : "black",
                            }}
                          >
                            {t("candidature spontanée.1")}
                          </Link>
                        </li>
                      </ul>
                    </MDBCollapse>
                  </li>
                  <li>
                    <Link to="/contact">
                      <span
                        style={{
                          color: location.pathname === "/contact" && "#0380b1",
                        }}
                      >
                        Contact
                      </span>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="navb"
          style={{ background: location.pathname === "/" && "#eef5f7" }}
        >
          <div
            className="row "
            style={{
              height: 70,
              margin: 0,
              width: "100%",
              boxShadow: shadow && "0px 1px 5px 0px rgba(0,0,0,0.75)",
              background: shadow && "white",
              transition: "background 0.4s",
            }}
          >
            <div
              className="col-4 first"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to="/">
                <img
                  src="/sources/vibtic.webp"
                  loading="lazy"
                  alt="vibtic"
                  style={{
                    width: 120,
                    height: 70,
                    transition: "width 0.5s",
                    marginTop: 5,
                    marginLeft: "-30%",
                  }}
                />
              </Link>
            </div>
            <div className=" col-6 second">
              <div
                className="drop"
                style={{
                  position: "relative",
                }}
              >
                <Link
                  to="/"
                  onMouseEnter={() => {
                    setDrop({ ...drop, valeur: 1 });
                  }}
                  onClick={() => {
                    setDrop({ ...drop, valeur: 1 });
                  }}
                  style={{ color: location.pathname === "/" && "#0380b1" }}
                >
                  {t("Accueil.1")}
                </Link>
              </div>
              <div
                className="drop"
                style={{
                  position: "relative",
                }}
              >
                <span
                  onMouseEnter={() => {
                    setDrop({ ...drop, valeur: 2 });
                  }}
                  onClick={() => {
                    setDrop({ ...drop, valeur: 2 });
                  }}
                  style={{
                    color:
                      (location.pathname === "/projet" ||
                        location.pathname === "/information" ||
                        location.pathname === "/reseau" ||
                        location.pathname === "/web" ||
                        location.pathname === "/cloud" ||
                        location.pathname === "/data") &&
                      "#0380b1",
                  }}
                >
                  Services
                  <MDBIcon icon="angle-down" />
                </span>
                {drop.valeur === 2 && (
                  <div
                    style={{
                      position: "absolute",
                      top: 35,
                      right: 0,
                      width: "max-content",
                      background: shadow && "white",
                      transition: "background 0.4s",
                    }}
                    onMouseLeave={() => {
                      setDrop({ ...drop, valeur: 0 });
                    }}
                  >
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                        background:
                          !shadow && location.pathname === "/" && "#eef5f7",
                      }}
                    >
                      <li>
                        <Link
                          to="/projet"
                          style={{
                            fontSize: 13,
                            color: location.pathname === "/projet" && "#0380b1",
                          }}
                        >
                          {t("gestion et management de projet.1")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/information"
                          style={{
                            fontSize: 13,
                            color:
                              location.pathname === "/information" && "#0380b1",
                          }}
                        >
                          {t("système d'information.1")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/reseau"
                          style={{
                            fontSize: 13,
                            color: location.pathname === "/reseau" && "#0380b1",
                          }}
                        >
                          {t("réseaux & sécurité.1")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/web"
                          style={{
                            fontSize: 13,
                            color: location.pathname === "/web" && "#0380b1",
                          }}
                        >
                          {t("développement informatique.1")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/cloud"
                          style={{
                            fontSize: 13,
                            color: location.pathname === "/cloud" && "#0380b1",
                          }}
                        >
                          {t("solution cloud.1")}
                        </Link>
                      </li>
                      <li style={{ border: "none" }}>
                        <Link
                          to="/data"
                          style={{
                            fontSize: 13,
                            color: location.pathname === "/data" && "#0380b1",
                          }}
                        >
                          {t("data center.1")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div
                className="drop"
                style={{
                  position: "relative",
                }}
              >
                <span
                  onMouseEnter={() => {
                    setDrop({ ...drop, valeur: 3 });
                  }}
                  onClick={() => {
                    setDrop({ ...drop, valeur: 3 });
                  }}
                  style={{
                    color:
                      (location.pathname === "/candidature" ||
                        location.pathname === "/offres") &&
                      "#0380b1",
                  }}
                >
                  {t("Carrière.1")}
                  <MDBIcon icon="angle-down" />
                </span>
                {drop.valeur === 3 && (
                  <div
                    style={{
                      position: "absolute",
                      top: 35,
                      right: 0,
                      width: "max-content",
                      background: shadow && "white",
                      transition: "background 0.4s",
                    }}
                    onMouseLeave={() => {
                      setDrop({ ...drop, valeur: 0 });
                    }}
                  >
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                        background:
                          !shadow && location.pathname === "/" && "#eef5f7",
                      }}
                    >
                      <li>
                        <Link
                          to="/offres"
                          style={{
                            fontSize: 13,
                            color: location.pathname === "/offres" && "#0380b1",
                          }}
                        >
                          {t("nos offres d'emploi.1")}
                        </Link>
                      </li>
                      <li style={{ border: "none" }}>
                        <Link
                          to="/candidature"
                          style={{
                            fontSize: 13,
                            color:
                              location.pathname === "/candidature" && "#0380b1",
                          }}
                        >
                          {t("candidature spontanée.1")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div style={{ position: "relative", margin: "0px 16px" }}>
                <Link
                  to="/contact"
                  style={{
                    color: location.pathname === "/contact" && "#0380b1",
                  }}
                >
                  Contact
                </Link>
              </div>
            </div>
            <div className="col-2 login-user">
              {!localStorage.token
                ? null
                : // location.pathname !== "/connexion" && (
                  //     <span
                  //       style={{
                  //         fontSize: 15,
                  //         color: "white",
                  //         cursor: "pointer",
                  //         padding: "5px 10px 8px",
                  //         backgroundColor: "#0380b1",
                  //         borderRadius: 25,
                  //       }}
                  //       title={t("Mon espace.1")}
                  //       onClick={() => history.push("/connexion")}
                  //     >
                  //       Connexion
                  //     </span>
                  //   )
                  location.pathname !== "/moncompte" && (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="div">
                        <img
                          src={auth.user.avatar}
                          className="user-avatar"
                          alt="user"
                        />
                      </MDBDropdownToggle>
                      <MDBDropdownMenu basic>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            height: "100%",
                            padding: 10,
                          }}
                          className="drop-user"
                        >
                          <img
                            style={{ borderRadius: "50%", marginBottom: 5 }}
                            src={auth.user.avatar}
                            height="55px"
                            width="55px"
                            alt="avatar"
                          />
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{ fontWeight: 600, margin: "2px 0px" }}
                            >
                              <b>{auth.user.fname + " " + auth.user.lname}</b>
                            </span>
                            <span style={{ color: "black", margin: "2px 0px" }}>
                              {auth.user.email}
                            </span>
                          </div>
                          <button
                            className="account"
                            onClick={() => history.push("/moncompte")}
                            style={{ marginBottom: "5px" }}
                          >
                            <i
                              className="fas fa-cog"
                              style={{ marginRight: 5 }}
                            ></i>
                            {t("Mon compte.1")}
                          </button>
                          <button
                            className="account"
                            onClick={onLogoutClick}
                            style={{ marginBottom: "5px" }}
                          >
                            <i
                              className="fas fa-sign-out-alt"
                              style={{ marginRight: 5 }}
                            ></i>
                            {t("Déconnexion.1")}
                          </button>
                        </div>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
