import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop";
import "./i18n";
import {Provider} from "react-redux"
import store from "./store"

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Suspense fallback={null}>
    <Provider store={store}>
      <App />
      </Provider>
    </Suspense>
  </BrowserRouter>,
document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
