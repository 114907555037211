import axios from "axios";
import { GET_ERRORS, GET_OFFRE } from "./types";

export const getOffre = () => (dispatch) => {
  axios
    .get("/offre")
    .then((res) => {
      dispatch({
        type: GET_OFFRE,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
