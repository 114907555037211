import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/projet.css";
import { WEB } from "../actions/types";
function Web() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.web &&
        dispatch({
          type: WEB,
        });
    };
    !load.web && (img.src = "/sources/web-msg.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.web ? (
        <div>
          <div className="projet-welcome">
            <h2>{t("Développement Informatique.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/web">{t("Développement Informatique.1")}</Link>
            </div> */}
          </div>
          <div className="projet-msg">
            <div>
              <h2 style={{ color: "#db8437  " }}>
                {t("Développement Informatique.1")}
              </h2>
              <p>
                <b>VIBTIC</b>{" "}
                {t(
                  "vous propose des solutions sur mesure et de qualité répondant à vos besoins Fonctionnalités et principales caractéristiques en lien avec votre secteur d’activité, design unique, contenu flexible, performance.1"
                )}
                … {t("Nous avons pensé à tout.1")}.
              </p>
            </div>
            <div>
              <img src="/sources/web-msg.webp" loading="lazy" alt="web" />
            </div>
          </div>
          <div className="projet-para">
            <h2>
              {t(
                "Comment nous vous accompagnons dans la réalisation de vos projets informatiques.1"
              )}{" "}
              !
            </h2>
            <div>
              <p>
                {t(
                  "Le développement informatique est composé de 3 types de développement différents.1"
                )}
                .
              <br/>
                <span>
                  • {t("Le développement logiciel.1")}
                  <br />• {t("Le développement web.1")}
                  <br />• {t("Le développement mobile.1")}
                </span>
             
                {t(
                  "Nous mettons à la disposition de nos clients tout notre savoir-faire dans la R&D logicielle et le développement spécifique.1"
                )}
              <br/>
                {t(
                  "Grâce à une équipe technologiquement mâture et composée d'ingénieurs issus des meilleures écoles d'ingénieurs, nous assurons toutes les phases de votre projet allant de la définition des choix technologiques au support.1"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Web;
