import {GET_AUTO} from "../actions/types";


  const initialState = {
    auto:[]
  };
  export default function auto(state = initialState, action) {
    switch (action.type) {
      case GET_AUTO:
        return {
          ...state,
         auto:action.payload
        };
      default:
        return state;
    }
  }