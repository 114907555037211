import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoDelete, getAutoAuthUser } from "../actions/authaction";
import { useTranslation } from "react-i18next";
import "../style/autoList.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
// import { GET_ERRORS } from "../actions/types";

function AutoList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auto = useSelector((state) => state.auto.auto);
  const auth = useSelector((state) => state.auth);
  // const error = useSelector((state) => state.errors.errors);
  const [autoId, setAutoId] = useState("");
  const [modal, setModal] = useState(false);
  useEffect(() => {
    dispatch(getAutoAuthUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (error && error.delete) {
  //     toggle();
  //     dispatch({
  //       type: GET_ERRORS,
  //       payload: {},
  //     });
  //   }
  // });
  const toggle = () => {
    setModal(!modal);
  };
  const deleteAuto = (id) => {
    toggle();
    dispatch(autoDelete(id));
  };

  useEffect(() => {}, [auto]);

  return (
    <div>
      <div>
        <h3 style={{ textAlign: "center", marginBottom: 30 }}>
          {t("Liste d'autorisation.1")}
        </h3>
      </div>
      {auto && auto.length !== 0 ? (
        <>
          {auto
            .slice(0)
            .reverse()
            .filter((el) => el.userId === auth.user._id)
            .map((el) => {
              return (
                <div key={el._id} className="collap-auto">
                  <div>
                    <div>
                      <p>{t("Raison.1")}</p>
                      {el?.raison}
                    </div>
                    <div>
                      {!el.etat ? (
                        <img
                          title={t("supprimer.1")}
                          src="/sources/delete.webp"
                          loading="lazy"
                          alt="corbeille"
                          onClick={() => {
                            toggle();
                            setAutoId(el._id);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>{t("Raison.2")} :</p>
                      {el?.response}
                    </div>
                    <div></div>
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        auto && (
          <div className="no-offre">
            <p>
              <i className="fas fa-exclamation"></i>
              {t("Liste vide.1")}.
            </p>
          </div>
        )
      )}
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>
          {t("Supprimer la demande d'autorisation.1")}
        </MDBModalHeader>
        <MDBModalBody>
          {t("êtes-vous sûr de vouloir supprimer la demande.1")} ?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            className="no-delete"
            onClick={toggle}
            style={{
              color: "#0380b1",
              border: "1px solid #0380b1",
              borderRadius: 5,
            }}
          >
            {t("Fermer.1")}
          </MDBBtn>
          <MDBBtn
            className="yes-delete"
            onClick={() => deleteAuto(autoId)}
            style={{ color: "white", borderRadius: 5 }}
          >
            {t("Supprimer.1")}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
}

export default AutoList;
