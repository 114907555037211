import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/projet.css";
import { PROJET } from "../actions/types";
function Projet() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.projet &&
        dispatch({
          type: PROJET,
        });
    };
    !load.projet && (img.src = "/sources/projet-msg.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.projet ? (
        <div>
          <div className="projet-welcome">
            <h2>{t("Gestion et management de Projet.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/projet">{t("Gestion et management de projet.1")}</Link>
            </div> */}
          </div>
          <div className="projet-msg">
            <div>
              <h2>{t("Gestion et management de Projet.1")}</h2>
              <p>
                <b>VIBTIC</b>{" "}
                {t(
                  "apporte ce niveau élevé d’expertise forte d’une expérience dans le domaine informatique.1"
                )}
                .
                {t(
                  "Elle a également développé une écoute intelligente des besoins des clients et procède ainsi à une analyse rigoureuse de l’environnement pour garantir un résultat à la hauteur des espérances de ses clients.1"
                )}
                .
              </p>
            </div>
            <div>
              <img src="/sources/projet-msg.webp" loading="lazy" alt="projet" />
            </div>
          </div>
          <div className="projet-para">
            <h2>
              {t(
                "Que vous apporte concrètement la Gestion de Projet et pourquoi est-ce si important.1"
              )}{" "}
              ?
            </h2>
            <div>
              <p>
                {t(
                  "Grâce à une bonne gestion de projet, vous pourrez vous assurer que les tâches menées visant à atteindre les différents objectifs soient maintenues et respectées.1"
                )}
                .{" "}
                {t(
                  "De plus, les qualités de gestion permettront d’éviter les risques, mais aussi d’utiliser de manière efficace et effective les ressources mises à la disposition du groupe de travail.1"
                )}
                .<br/>
                {t(
                  "Par ailleurs, les membres de l’équipe pourront être correctement guidés, comprendront leurs responsabilités et les résultats que l’on attend d’eux, ils seront toujours soucieux de respecter le calendrier des tâches afin d’achever le projet à temps et dans le budget alloué.1"
                )}
                <b>{t("La Gestion de projet fournit à la fois.1")} : </b>
              </p>
              <p>
                <span>
                  •{" "}
                  {t(
                    "Une plus grande probabilité d’obtenir un résultat souhaité.1"
                  )}
                  <br />•{" "}
                  {t(
                    "L’efficacité du groupe de travail à satisfaire les différents besoins des parties prenantes du projet.1"
                  )}
                  <br />•{" "}
                  {t(
                    "Et enfin la garantie d’une bonne utilisation des ressources.1"
                  )}
                </span>
              </p>
              <p>
                <b>VIBTIC </b>
                {t(
                  "vous accompagne dans vos projets en fonction de vos besoins, on vous conseille afin d’améliorer votre performance et améliorer vos compétences nous mettons sur pied votre projet tout en respectant notre charte professionnelle  coûts, risques, délais, qualité.1"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Projet;
