import { GET_ERRORS, GET_ERRORS_PASSWORD, GET_ERRORS_CONGE, GET_ERRORS_DEMANDE,
  GET_ERRORS_START_TEST, GET_ERRORS_CNX } from "../actions/types";
const initialState = {
  errors:null,
  errorMsg: "",
  congeAdded:"",
  demande:"",
  startTest:"",
  cnx_errors:{}
};
export default function err(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
      case GET_ERRORS_CNX:
      return {
        ...state,
        cnx_errors: action.payload,
      };
      case GET_ERRORS_PASSWORD:
        return {
          ...state,
          errorMsg: action.payload,
        };
        case GET_ERRORS_CONGE:
        return {
          ...state,
          congeAdded: action.payload,
        };
        case GET_ERRORS_DEMANDE:
        return {
          ...state,
          demande: action.payload,
        };
        case GET_ERRORS_START_TEST:
        return {
          ...state,
          startTest: action.payload,
        };
        

    default:
      return state;
  }
}