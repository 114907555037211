import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newTask, getTaskByID, updateTask } from "../actions/task";
// import { GET_ERRORS } from "../actions/types";
import { useTranslation } from "react-i18next";
import "../style/conge.css";

function Activity({ idActivity }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  // const errors = useSelector((state) => state.errors.errors);
  const task = useSelector((state) => state.task.task);
  const [btnForm, setBtnForm] = useState(false);
  const [contactErreur, setContactErreur] = useState(true);
  const [taskData, setTaskData] = useState({
    start: "",
    end: "",
    task: "",
  });
  const [errData, setErrData] = useState({
    start: false,
    end: false,
    task: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (errors.congeAdded) {
  //     setBtnForm(false);
  //     setTaskData({ ...taskData, start: "", end: "", task: "" });
  //     document.getElementById("justif").value = "";
  //     setContactErreur(false);
  //     setTimeout(() => {
  //       setContactErreur(true);
  //     }, 3300);

  //     dispatch({
  //       type: GET_ERRORS,
  //       payload: {},
  //     });
  //   }
  // });
  const onChange = (e) => {
    if (e.target.id === "justif")
      setTaskData({ ...taskData, [e.target.id]: e.target.files[0] });
    else setTaskData({ ...taskData, [e.target.id]: e.target.value });
    setErrData({ ...errData, [e.target.id]: false });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (taskData.start === "" || taskData.end === "" || taskData.task === "") {
      setErrData({
        ...errData,
        start: !taskData.start,
        end: !taskData.end,
        task: !taskData.task,
      });
    } else {
      setBtnForm(true);
      if (idActivity) {
        dispatch(updateTask({ ...taskData, _id: idActivity }));
      } else {
        dispatch(newTask({ ...taskData, userId: auth.user._id }));
      }
      setTaskData({
        start: "",
        end: "",
        task: "",
      });
    }
  };

  useEffect(() => {
    if (idActivity) {
      dispatch(getTaskByID(idActivity));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idActivity]);

  useEffect(() => {
    if (idActivity && task) {
      setTaskData(task);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);
  return (
    <div className="tab-conge">
      <div>
        <h3 style={{ textAlign: "center" }}>{t("Compte rendu.1")}</h3>
        <form className=" p-4" onSubmit={onSubmit}>
          <label>{t("Task.1")}</label>
          <textarea
            type="text"
            id="task"
            rows={4}
            className="form-control mb-4"
            value={taskData.task}
            onChange={onChange}
            style={{ border: errData.task && "1px solid red" }}
          />

          <label>{t("Date du début.1")}</label>
          <input
            type="date"
            id="start"
            className="form-control mb-4"
            value={taskData.start}
            onChange={onChange}
            // min={new Date().toISOString().split("T")[0]}
            style={{ border: errData.start && "1px solid red" }}
          />
          <label>{t("Date de fin.1")}</label>
          <input
            min={taskData.start}
            type="date"
            id="end"
            className="form-control mb-4"
            value={taskData.end}
            onChange={onChange}
            disabled={!taskData.start}
            style={{ border: errData.end && "1px solid red" }}
          />
          <button
            disabled={btnForm}
            className="btn  btn-block"
            type="submit"
            style={{ background: "#464646", color: "white" }}
          >
            {t("Envoyer.1")}
          </button>
        </form>
      </div>

      <div
        className="contact-alert"
        style={{
          opacity: contactErreur && 0,
          visibility: contactErreur && "hidden",
          top: "80%",
        }}
      >
        {t("Votre demande a été envoyé avec succès.1")}
      </div>
    </div>
  );
}

export default Activity;
