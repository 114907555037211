import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../style/login.css";
import { Link } from "react-router-dom";
import { GET_SUCCESS_PASSWORD, GET_ERRORS_PASSWORD } from "../actions/types";
import { forgetPassword } from "../actions/authaction";

function Recover({ history }) {
  const errors = useSelector((state) => state.errors.errorMsg);
  const successes = useSelector((state) => state.success.password_success);
  const dispatch = useDispatch();
  // const [passtype, setpasstype] = useState("password");
  const [email, setEmail] = useState();
  // const [size, setSize] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     if (window.innerWidth <= 991) {
  //       setSize(true);
  //     } else {
  //       setSize(false);
  //     }
  //   });
  // });

  // useEffect(() => {
  //   if (window.innerWidth <= 991) {
  //     setSize(true);
  //   }
  // }, []);

  const { t } = useTranslation();

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(forgetPassword({ email: email }));
  };

  useEffect(() => {
    setTimeout(() => {
      if (successes) {
        dispatch({
          type: GET_SUCCESS_PASSWORD,
          payload: null,
        });
        history.push("/connexion");
      }
      if (errors) {
        dispatch({
          type: GET_ERRORS_PASSWORD,
          payload: null,
        });
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successes, errors]);

  return (
    <div style={{ margin: "12% 28% 1%" }}>
      <div className="input-form">
        <div>
          <form onSubmit={onSubmit}>
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="email">
                E-mail
              </label>
              <input
                type="email"
                id="email"
                required
                className="form-control"
                onChange={onChange}
                value={email}
                autoFocus
                style={{ backgroundColor: "white" }}
              />
            </div>
            <div className="form-outline mb-4">
              <Link to="/connexion">{t("Se connecter.1")}</Link>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              style={{ width: 170, margin: "0px auto", borderRadius: 5 }}
            >
              {t("Se connecter.3")}
            </button>
          </form>
        </div>
      </div>
      <div>
        {" "}
        <img src="/sources/ellipse4.webp" loading="lazy" alt="ellipse" />
      </div>
      {successes ? (
        <div
          className="recover-alert"
          style={{
            opacity: 100,
            visibility: "visible",
          }}
        >
          {successes}
        </div>
      ) : null}

      {errors ? (
        <div
          className="recover-alert"
          style={{
            opacity: 100,
            visibility: "visible",
            background: "red",
          }}
        >
          {errors}
        </div>
      ) : null}
    </div>
  );
}

export default Recover;
