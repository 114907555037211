import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../style/offres.css";
import { useDispatch, useSelector } from "react-redux";
import { getOffre } from "../actions/offreaction";
import { OFFRES } from "../actions/types";
function Offres() {
  const offres = useSelector((state) => state.offres.offres);
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getOffre());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.offres &&
        dispatch({
          type: OFFRES,
        });
    };
    !load.offres && (img.src = "/sources/img-offre.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.offres ? (
        <div>
          <div className="offre-welcome">
            <h2>{t("Offres D'emploi.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/offres">{t("Nos Offres.1")}</Link>
            </div> */}
          </div>
          <div className="img-offre">
            <img src="/sources/img-offre.webp" alt="welcome" />
            <p>
              {t(
                "Vous souhaitez rejoindre un groupe ambitieux et à taille humaine où l’expertise se mêle avec l’esprit d’équipe, nous vous attendons.1"
              )}
            </p>
          </div>
          {offres && offres.length !== 0 ? (
            <div className="row offres-list">
              {offres
                .slice(0)
                .reverse()
                .map((el) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-12" key={el._id}>
                      <div className="offre-card">
                        <div className="content">
                          <div>
                            <h2>
                              {" "}
                              <strong>
                                {el.titre.length <= 20
                                  ? el.titre
                                  : el.titre.slice(0, 20) + ".."}
                              </strong>
                            </h2>
                            <p>{el.created_at.split("T")[0]}</p>
                          </div>
                          <p>
                            {el.description.length <= 160
                              ? el.description
                              : el.description.slice(0, 160) + ".."}
                          </p>
                          <Link to={"/offres/" + el._id}>
                            {t("VOIR L'OFFRE.1")}
                          </Link>
                        </div>
                        <div>
                          <h2>
                            {" "}
                            <strong>
                              {el.titre.length <= 20
                                ? el.titre
                                : el.titre.slice(0, 20) + ".."}
                            </strong>
                          </h2>
                          <p>{el.created_at.split("T")[0]}</p>
                        </div>
                        {el.pays && (
                          <p className="place-offre">
                            <i className="fas fa-map-marker-alt"></i>
                            {el.pays}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            offres && (
              <div className="no-offre">
                <p>
                  <i className="fas fa-exclamation"></i>
                  {t(
                    "Pas d'offres disponibles pour le moment, vous pouvez nous envoyer votre candidature spontanée.1"
                  )}
                  .
                </p>
              </div>
            )
          )}
          <div className="spontanee">
            {/* <Link to="/candidature">{t("Candidature spontanée.1")}</Link> */}
          </div>
          <div>
            {" "}
            <img src="/sources/ellipse4.webp" alt="ellipse" />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Offres;
