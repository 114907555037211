import { CANDIDATE_TEST, CANDIDATE_QUESTIONS } from "../actions/types";

const initialState = {
  candidateTest:{},
  testQuestions:[]
};

export default function qcm(state = initialState, action) {
  switch (action.type) {
    
    case CANDIDATE_TEST:
      return {
      ...state,
      candidateTest: action.payload,
    };
    case CANDIDATE_QUESTIONS:
      return {
      ...state,
      testQuestions: action.payload,
    };
    default:
      return state;
  }
}
