import { HOME, APP, SERVICES, COMPETENCE, CONTACT, OFFRES, CANDIDATURE, CONNEXION, VIBTIC, PROJET, INFORMATION, RESEAU, WEB, CLOUD, DATA } from "../actions/types";

const load = {
  app: false,
  home: false,
  services: false,
  competence: false,
  contact:false,
  offres:false,
  candidature:false,
  connexion:false,
  vibtic:false,
  projet:false,
  information:false,
  reseau:false,
  web:false,
  cloud:false,
  data:false
};
export default function loading(state = load, action) {
  switch (action.type) {
    case APP:
      return { ...state, app: true };
    case HOME:
      return { ...state, home: true };
    case SERVICES:
      return { ...state, services: true };
    case COMPETENCE:
      return { ...state, competence: true };
      case CONTACT:
        return { ...state, contact: true };
        case OFFRES:
          return { ...state, offres: true };
          case CANDIDATURE:
            return { ...state, candidature: true };
            case CONNEXION:
              return { ...state, connexion: true };
              case VIBTIC:
              return { ...state, vibtic: true };
              case PROJET:
              return { ...state, projet: true };
              case INFORMATION:
              return { ...state, information: true };
              case RESEAU:
                return { ...state, reseau: true };
                case WEB:
                  return { ...state, web: true };
                  case CLOUD:
                  return { ...state, cloud: true };
                  case DATA:
                    return { ...state, data: true };
      default:
          return state
  }
}
