import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../style/projet.css";
import { DATA } from "../actions/types";
function Data() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      !load.data &&
        dispatch({
          type: DATA,
        });
    };
    !load.data && (img.src = "/sources/data-msg.webp");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {load && load.data ? (
        <div>
          <div className="projet-welcome">
            <h2>{t("Data Center.1")}</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/data">{t("Data Center.1")}</Link>
            </div> */}
          </div>
          <div className="projet-msg">
            <div>
              <h2 style={{ color: "#4baefb" }}>
                {t("Data Center.1")}
              </h2>
              <p>
                {t(
                  "Le Data Center devient aujourd’hui le point névralgique du système d’information et de communication des entreprises.1"
                )}
                .
              <br/>
                {t(
                  "Il doit donc être considéré comme maillon critique dans la chaîne de valeur de production et de stockage des données.1"
                )}
                .
              </p>
            </div>
            <div>
              <img src="/sources/data-msg.webp" loading="lazy" alt="data" />
            </div>
          </div>
          <div className="projet-para">
            <div>
              <p>
                {t(
                  "Avec la généralisation de la virtualisation et des modèles Cloud, les directions des systèmes d'informations revoient la conception, l’organisation, l’exploitation et l’optimisation de leurs salles informatiques et cela quel que soit leur taille Mais pour autant tout aussi capitaux Les centres informatiques vont devoir faire face à de nouvelles contraintes, notamment énergétiques et environnementales, qui pourront imposer des évolutions parfois lourdes.1"
                )}
                .
              <br/>
                {t(
                  "Notre vision consolidée des problématiques d’urbanisation et des contraintes IT nous permet de conseiller nos clients sur l’intégralité de cette problématique.1"
                )}
                .
              <br/>
                {t(
                  "Forte de son expérience et de la connaissance de ses clients, VIBTIC a identifié les principaux enjeux actuels autour du Datacenter.1"
                )}{" "}
                :
              <br/>
                  <span>
                  •	{t("Réduire et maitriser les investissements et les coûts d’exploitation.1")}
                  <br/>
                  •	{t("Assurer la sécurité des données et la continuité d’activité.1")}
                  <br/>
                  •	{t("Rendre plus flexible la mise à disposition des ressources (organisation & infrastructure).1")}
                  <br/>
                  •	{t("Inscrire le Data Center dans un modèle d’offre de services.1")}
                  <br/>
                  •	{t("Réduire la consommation énergétique, polluer moins en offrant la même qualité de service.1")}
                 <br/>
                 •	{t("Avoir la maitrise opérationnelle du Datacenter.1")}
                 <br/>
                 •	{t("Optimiser et maitriser la croissance de la salle informatique.1")}
                 <br/>
                 •	{t("Répondre aux contraintes législatives  bâtiment, rejets atmosphériques.1")}
                  </span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Data;
