import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT } from "../actions/types";
import "../style/contact.css";
function Contact() {
  const load = useSelector((state) => state.load);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [contactData, setContactData] = useState({
    nom: "",
    email: "",
    sujet: "",
    message: "",
  });
  const [valideForm, setValidForm] = useState({
    message: false,
  });
  const [emailValid, setEmailValid] = useState(false);
  const [nomValid, setNomValid] = useState(false);
  const [formError, setFormError] = useState(false);
  const [contactErreur, setContactErreur] = useState(true);
  const [btnForm, setBtnForm] = useState(false);

  useEffect(() => {
    const img = new Image();
    const img1 = new Image();
    const img2 = new Image();

    img2.onload = () => {
      img1.onload = () => {
        img.onload = () => {
          !load.contact &&
            dispatch({
              type: CONTACT,
            });
        };
        !load.contact && (img.src = "/sources/contact1.webp");
      };

      !load.contact && (img1.src = "/sources/contact2.webp");
    };
    !load.contact && (img2.src = "/sources/contact3.webp");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChange = (e) => {
    formError && setFormError(false);
    setContactData({ ...contactData, [e.target.id]: e.target.value });
    e.target.id === "message" &&
      setValidForm({ ...valideForm, message: false });
    e.target.id === "email" && setEmailValid(false);
    e.target.id === "nom" && setNomValid(false);
  };

  const input_fields = {
    message: /[a-zA-Z0-9]/,
    nom: /[a-zA-Z]/,
    //eslint-disable-next-line
    email: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!input_fields.email.test(contactData.email)) setEmailValid(true);
    else setEmailValid(false);
    if (!input_fields.message.test(contactData.message))
      setValidForm({ ...valideForm, message: true });
    else setValidForm({ ...valideForm, message: false });
    if (!input_fields.nom.test(contactData.nom)) setNomValid(true);
    else setNomValid(false);
    if (
      !valideForm.message &&
      !emailValid &&
      !nomValid &&
      contactData.email.length !== 0 &&
      contactData.message.length !== 0 &&
      contactData.nom.length !== 0
    ) {
      setBtnForm(true);
      let newContact = {
        nom: contactData.nom,
        email: contactData.email,
        sujet: contactData.sujet,
        message: contactData.message,
      };
      axios
        .post("/contact/contactus", newContact)
        .then((res) => {
          setContactErreur(false);
          setTimeout(() => {
            setContactErreur(true);
          }, 3300);
          setContactData({
            ...contactData,
            nom: "",
            email: "",
            sujet: "",
            message: "",
          });
          setBtnForm(false);
        })
        .catch((err) => {
          setContactErreur(true);
          setBtnForm(false);
        });
    } else setFormError(true);
  };
  return (
    <>
      {load && load.contact ? (
        <div>
          <div className="contact-welcome">
            <h2>Contact</h2>
            {/* <div>
              <Link to="/">{t("Accueil.1")}</Link>
              <div></div>
              <Link to="/contact">Contact</Link>
            </div> */}
          </div>
          <div className="cord">
            <h2>
              {t(
                "N’hésitez pas à nous écrire pour toutes questions ou demandes d’informations.1"
              )}
            </h2>
            <div>
              <div>
                <img src="/sources/contact1.webp" loading="lazy" alt="tel" />
                <h4>{t("Téléphone.1")}</h4>
                <p>+33651527439</p>
              </div>
              <div>
                <img src="/sources/contact2.webp" loading="lazy" alt="localisation" />
                <h4>{t("Localisation.1")}</h4>
                <p>
                  3 AVENUE CHARLES DE GAULLE 94470 BOISSY-SAINT-LEGER, FRANCE
                </p>
              </div>
              <div>
                <img src="/sources/contact3.webp" loading="lazy" alt="mail" />
                <h4>E-mail</h4>
                <p>contact@vibtic.com</p>
              </div>
            </div>
          </div>
          <div className="formu">
            <h2>{t("Ouvrir une demande.1")}</h2>
            <div className="form-contact">
              <form className=" p-5" onSubmit={onSubmit}>
                <label htmlFor="nom">{t("Nom.1")}*</label>
                <input
                  type="text"
                  id="nom"
                  value={contactData.nom}
                  className="form-control mb-2"
                  onChange={onChange}
                  // autoFocus
                  style={{ border: nomValid && "1px solid red" }}
                />
                <label htmlFor="email">E-mail*</label>
                <input
                  type="email"
                  id="email"
                  value={contactData.email}
                  className="form-control mb-2"
                  onChange={onChange}
                  style={{ border: emailValid && "1px solid red" }}
                />
                <label htmlFor="sujet">{t("Sujet.1")}</label>
                <input
                  type="text"
                  id="sujet"
                  value={contactData.sujet}
                  className="form-control mb-2"
                  onChange={onChange}
                />
                <div className="form-group">
                  <label htmlFor="message">Message*</label>
                  <textarea
                    className="form-control"
                    id="message"
                    value={contactData.message}
                    rows="5"
                    onChange={onChange}
                    style={{ border: valideForm.message && "1px solid red" }}
                  ></textarea>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "start",
                    color: "gray",
                    marginBottom: 10,
                  }}
                >
                  *{t("Champs requis.1")}
                </div>
                <button
                  disabled={btnForm}
                  className="btn  btn-block"
                  type="submit"
                  style={{ background: "white" }}
                >
                  {t("Envoyer.1")}
                </button>
              </form>
              <div
                className="erreur-form"
                style={{ display: !formError && "none" }}
              >
                <p>
                  {t("Un ou plusieurs champs contiennent une erreur.1")}.{" "}
                  {t("Veuillez vérifier et essayer à nouveau.1")}
                </p>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <img src="/sources/ellipse4.webp" loading="lazy" alt="ellipse" />
          </div>
          <div
            className="contact-alert"
            style={{
              opacity: contactErreur && 0,
              visibility: contactErreur && "hidden",
            }}
          >
            {t("Votre message a été envoyé, merci de votre intérêt.1")}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/sources/loader.svg" alt="loader" />
        </div>
      )}
    </>
  );
}

export default Contact;
